import {convertServiceSupplierArrayToObject} from 'shared/constants/helpers';
import {
  GET_ALL_HISTORY_POSTE_AFFILIATION,
  GET_ALL_HISTORY_POSTE_PAYMENT,
  GET_ALL_HISTORY_POSTE_RECHARGE,
  CONSULTATION_INVOICE,
  GET_ALL_RECLAMATION,
} from '../../shared/constants/ActionTypes';

const initialSettings = {
  historyPoste: [],
  historyAffiliation: [],
  historyRecharge: [],
  totalPages: 0,
  voucherpostStatus: false,
  consultation: null,
};

const posteCompReducer = (state = initialSettings, action) => {
  const f = {...state.facialsIDs};

  switch (action.type) {
    case GET_ALL_HISTORY_POSTE_PAYMENT: {
      return {
        ...state,
        historyPoste: action.payload.data,
        totalPages: action.payload.totalPages,
      };
    }
    case GET_ALL_RECLAMATION:
      return {
        ...state,
        totalpages: action.payload.totalPages,
      };

    case CONSULTATION_INVOICE: {
      return {
        ...state,
        consultation: action.payload,
      };
    }
    case GET_ALL_HISTORY_POSTE_AFFILIATION: {
      return {
        ...state,
        historyAffiliation: action.payload.items,
        totalPages: action.payload.totalPages,
      };
    }
    case GET_ALL_HISTORY_POSTE_RECHARGE: {
      return {
        ...state,
        historyRecharge: action.payload.items,
        totalPages: action.payload.totalPages,
      };
    }

    default:
      return state;
  }
};

export default posteCompReducer;
