import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import {amber, green} from '@material-ui/core/colors';
import IconButton from '@material-ui/core/IconButton';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import WarningIcon from '@material-ui/icons/Warning';
import {makeStyles} from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';

import Dialog from '@material-ui/core/Dialog';
import DialogContentText from '@material-ui/core/DialogContentText';
import Button from '@material-ui/core/Button';
import IntlMessages from '../../utility/IntlMessages';
import Box from '@material-ui/core/Box';
import {grey} from '@material-ui/core/colors';
import {Fonts} from 'shared/constants/AppEnums';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
} from '@material-ui/core';
import Collapse from '@material-ui/core/Collapse';
import {Alert, AlertTitle} from '@material-ui/lab';
const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};
const useStyles1 = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
  // root: {
  //   '& .MuiSnackbarContent-root': {
  //     flexGrow: 'initial',
  //     minWidth: '588px',
  //     height: '100px',
  //     [theme.breakpoints.down('sm')]: {
  //       minWidth: '250px',
  //       height: '60px',
  //     },
  //   },
  // },
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: theme.palette.primary.main,
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 30,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 20,
  },
  contentText: {
    color: 'black',
    fontSize: '24px',
  },
  image: {width: 100},
  btn: {
    marginLeft: 8,
    fontWeight: Fonts.MEDIUM,
  },
  contentText: {
    fontSize: 18,
    fontFamily: 'bold',
    color: grey[600],
  },
  buttonCancel: {
    width: '100%',
    height: 44,
    color: 'white',
    backgroundColor: 'red',
  },
  button: {
    width: '100%',
    height: 44,
    backgroundColor: '#003255',
    color: 'white',
    marginTop: 30,
  },
}));

const AppSnackbar = (props) => {
  const classes = useStyles1();

  const [open, setOpen] = React.useState(true);
  const {className, message, error, variant, ...other} = props;

  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction='down' ref={ref} {...props} />;
  });
  ////console.log(classes[variant])
  return (
    <div id='messageHandler' className={classes.root}>
      <Collapse in={open}>
        {variant.includes('error') ? (
          <Alert
            variant='outlined'
            severity='error'
            action={
              <IconButton
                aria-label='close'
                color='inherit'
                size='small'
                onClick={() => {
                  setOpen(false);
                }}>
                <CloseIcon fontSize='inherit' />
              </IconButton>
            }
            autoHideDuration={80000}>
            {/* <AlertTitle>Echec</AlertTitle> */}
            {message}
          </Alert>
        ) : (
          <Alert
            variant='outlined'
            severity='success'
            action={
              <IconButton
                aria-label='close'
                color='inherit'
                size='small'
                onClick={() => {
                  setOpen(false);
                }}>
                <CloseIcon fontSize='inherit' />
              </IconButton>
            }
            autoHideDuration={120000}>
            <AlertTitle>Succès</AlertTitle>
            {message}
          </Alert>
        )}
        {/* <Alert
          action={
            <IconButton
              aria-label='close'
              color='inherit'
              size='small'
              onClick={() => {
                setOpen(false);
              }}>
              <CloseIcon fontSize='inherit' />
            </IconButton>
          }>
          Close me!
        </Alert> */}
      </Collapse>
      {/* <Button
        disabled={open}
        variant='outlined'
        onClick={() => {
          setOpen(true);
        }}>
        Re-open
      </Button> */}
    </div>

    // <Dialog
    //   open={open}
    //   onClose={() => onClose()}
    //   fullWidth={true}
    //   maxWidth={maxWidth}
    //   aria-labelledby='simple-modal-title'
    //   aria-describedby='simple-modal-description'>
    //   <Box px={{xs: 5, md: 7}} pt={{xs: 4, md: 6}} pb={{xs: 2, md: 4}}>
    //     <Box mb={{xs: 6, md: 8, xl: 18}}>
    //       <Box
    //         flexDirection='column'
    //         display='flex'
    //         alignItems='center'
    //         justifyContent='center'>
    //         <span id='client-snackbar' className={classes.message}>
    //           {variant.includes('error') ? (
    //             <img
    //               className={classes.image}
    //               src={'/assets/images/icons/Error.png'}
    //               alt='error'
    //               width='100%'
    //             />
    //           ) : (
    //             <img
    //               className={classes.image}
    //               src={'/assets/images/icons/Success.png'}
    //               alt='success'
    //               width='100%'
    //             />
    //           )}
    //         </span>
    //         <DialogTitle
    //           className={classes.contentText}
    //           id='customized-dialog-title'>
    //           {variant.includes('error') ? 'Echec' : 'Success'}
    //         </DialogTitle>
    //         <DialogContent
    //           className={classes.contentText}
    //           id='alert-dialog-description'>
    //           {message}
    //         </DialogContent>
    //       </Box>
    //     </Box>

    //     <DialogActions>
    //       {/* <Button
    //       className={classes.btn}
    //       onClick={() => onClose()}
    //       color='primary'>
    //       <IntlMessages id='common.no' />
    //     </Button> */}
    //       <Button
    //         className={classes.btn}
    //         key='close'
    //         aria-label='close'
    //         variant='contained'
    //         color='primary'
    //         type='submit'
    //         onClick={() => onClose()}
    //         autoFocus>
    //         OK
    //       </Button>
    //     </DialogActions>
    //   </Box>
    // </Dialog>
  );
};
AppSnackbar.propTypes = {
  className: PropTypes.string,
  message: PropTypes.string,
  variant: PropTypes.oneOf(['error', 'info', 'success', 'warning']).isRequired,
};

export default AppSnackbar;

// import React from 'react';
// import PropTypes from 'prop-types';
// import clsx from 'clsx';
// import CheckCircleIcon from '@material-ui/icons/CheckCircle';
// import ErrorIcon from '@material-ui/icons/Error';
// import InfoIcon from '@material-ui/icons/Info';
// import CloseIcon from '@material-ui/icons/Close';
// import {amber, green} from '@material-ui/core/colors';
// import IconButton from '@material-ui/core/IconButton';
// import SnackbarContent from '@material-ui/core/SnackbarContent';
// import WarningIcon from '@material-ui/icons/Warning';
// import {makeStyles} from '@material-ui/core/styles';
// import Snackbar from '@material-ui/core/Snackbar';

// const variantIcon = {
//   success: CheckCircleIcon,
//   warning: WarningIcon,
//   error: ErrorIcon,
//   info: InfoIcon,
// };
// const useStyles1 = makeStyles((theme) => ({
//   root: {
//     '& .MuiSnackbarContent-root': {
//       flexGrow: 'initial',
//       minWidth: '150px',
//       height: '100px',
//       [theme.breakpoints.down('sm')]: {
//         minWidth: '250px',
//         height: '60px',
//       },
//     },
//   },
//   success: {
//     backgroundColor: green[600],
//   },
//   error: {
//     backgroundColor: theme.palette.error.dark,
//   },
//   info: {
//     backgroundColor: theme.palette.primary.main,
//   },
//   warning: {
//     backgroundColor: amber[700],
//   },
//   icon: {
//     fontSize: 25,
//   },
//   iconVariant: {
//     opacity: 0.9,
//     marginRight: theme.spacing(1),
//   },
//   message: {
//     display: 'flex',
//     alignItems: 'center',
//     fontSize: 20,
//   },
// }));
// const AppSnackbar = (props) => {
//   const classes = useStyles1();

//   const [open, setOpen] = React.useState(true);
//   const {className, message, variant, ...other} = props;
//   const Icon = variantIcon[variant];

//   const onClose = () => {
//     setOpen(false);
//   };

//   return (
//     <Snackbar
//       anchorOrigin={{
//         vertical: 'center',
//         horizontal: 'center',
//       }}
//       className={classes.root}
//       open={open}
//       autoHideDuration={60000}
//       onClose={onClose}>
//       <SnackbarContent
//         className={clsx(classes[variant], className)}
//         aria-describedby='client-snackbar'
//         message={
//           <span id='client-snackbar' className={classes.message}>
//             <Icon className={clsx(classes.icon, classes.iconVariant)} />
//             {message}
//           </span>
//         }
//         action={[
//           <IconButton
//             key='close'
//             aria-label='close'
//             color='inherit'
//             onClick={onClose}>
//             <CloseIcon className={classes.icon} />
//           </IconButton>,
//         ]}
//         {...other}
//       />
//     </Snackbar>
//   );
// };
// AppSnackbar.propTypes = {
//   className: PropTypes.string,
//   message: PropTypes.string,
//   variant: PropTypes.oneOf(['error', 'info', 'success', 'warning']).isRequired,
// };

// export default AppSnackbar;
