import React from 'react';
import {authRole} from 'shared/constants/AppConst';

export const couponsPagesConfig = [
  {
    auth: authRole.user,
    routes: [
      {
        exact: true,
        path: '/coupons',
        component: React.lazy(() => import('./Pages/coupons')),
      },
      {
        exact: true,
        path: '/coupons/menu/:id',
        component: React.lazy(() => import('./Pages/menu')),
      },

      {
        exact: true,
        path: '/coupons/menu/:param/type/:id',
        component: React.lazy(() => import('./Pages/Products/cardsGrid/index')),
      },
      {
        exact: true,
        path: '/coupons/menu/:param/product/:id',
        component: React.lazy(() => import('./Pages/Products/index')),
      },
      {
        exact: true,
        path: '/category',
        component: React.lazy(() => import('./Pages/category/index')),
      },
      {
        exact: true,
        path: '/category/product/:id',
        component: React.lazy(() => import('./Pages/Products/index')),
      },
      {
        exact: true,
        path: '/Historique-Coupons',
        component: React.lazy(() => import('./Pages/HistoryMintroute')),
      },
    ],
  },
];
