import React, {useEffect} from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import MoreIcon from '@material-ui/icons/MoreVert';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import LanguageSwitcher from '../../LanguageSwitcher';
import {getUserBalance, toggleNavCollapsed} from '../../../../redux/actions';
import {useDispatch, useSelector} from 'react-redux';
import Hidden from '@material-ui/core/Hidden';
import Box from '@material-ui/core/Box';
import SearchBar from '../../SearchBar';
import useStyles from './AppHeader.style';
import HeaderMessages from '../../HeaderMessages';
import Notifications from '../../Notifications';
import AppLogo from '../../../../shared/components/AppLogo';
import HeaderUser from '../../../../shared/components/HeaderUser';
import clsx from 'clsx';
import TotalStock from 'shared/components/TotalStock';
import TotalWallet from 'shared/components/TotalWallet';
import HeaderDate from 'shared/components/HeaderDate/headerDate';
import io from 'socket.io-client';
import {baseUrl} from 'shared/constants/AppConst';

const AppHeader = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const role = localStorage.getItem('role');
  // console.log("rrrrrrrrrrrollllleee", role)
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  useEffect(() => {
    const socket = io(baseUrl, {
      path: '/socket.io', // Add the path here
    });
    socket.emit('subscribe', 'VC-' + localStorage.getItem('user_id'));

    socket.on('globalNotification', (data) => {
      console.log('Socket connected');
      console.log('dataa', data);
    });
    if (role !== 'ROLE_TOPNET' && role !== 'ROLE_FOURNISSEUR') {
      console.log('first0000000000000');
      dispatch(getUserBalance());
    }
  }, [dispatch]);

  function handleMobileMenuClose() {
    setMobileMoreAnchorEl(null);
  }

  function handleMobileMenuOpen(event) {
    setMobileMoreAnchorEl(event.currentTarget);
  }

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{vertical: 'top', horizontal: 'right'}}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{vertical: 'top', horizontal: 'right'}}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}>
      <MenuItem className={classes.menuItemRoot}>
        {/* <HeaderMessages /> */}
      </MenuItem>
      <MenuItem className={classes.menuItemRoot}>
        {/* <Notifications />*/}
      </MenuItem>
      <TotalWallet />
      <TotalStock />
      {/* <LanguageSwitcher /> */}
    </Menu>
  );

  return (
    <>
      <AppBar color='inherit' className={clsx(classes.appBar, 'app-bar')}>
        <Toolbar className={classes.appToolbar}>
          <Hidden lgUp>
            <IconButton
              edge='start'
              className={classes.menuButton}
              color='inherit'
              aria-label='open drawer'
              onClick={() => dispatch(toggleNavCollapsed())}>
              <MenuIcon className={classes.menuIcon} />
            </IconButton>
          </Hidden>
          <Box className={classes.sectionDesktop}>
            <AppLogo />
          </Box>
          <Box className={classes.grow} />

          {role !== 'ROLE_TOPNET' &&
          role !== 'ROLE_PDV_VIP' &&
          role !== 'ROLE_FOURNISSEUR' ? (
            <Box className={classes.headerbuttons}>
              {/* <Box>
                <HeaderDate />
              </Box> */}
              <Box>
                <TotalStock />
              </Box>
              <Box className={classes.sectionDesktop}>
                <Box>
                  <TotalWallet />
                </Box>
              </Box>

              {/* <LanguageSwitcher /> */}
              {/* <SearchBar borderLight placeholder='Search…' /> */}
              {/* <HeaderMessages /> */}
              {/* <Notifications /> */}
            </Box>
          ) : role === 'ROLE_PDV_VIP' ? (
            <Box className={classes.headerbuttons}>
              <Box>
                <TotalStock />
              </Box>
              <Box className={classes.sectionDesktop}>
                <Box>
                  <TotalWallet />
                </Box>
              </Box>
            </Box>
          ) : (
            <></>
          )}
          <HeaderUser />
          {/* <Box className={classes.sectionMobile}>
            <IconButton
              aria-label='show more'
              aria-controls={mobileMenuId}
              aria-haspopup='true'
              onClick={handleMobileMenuOpen}
              color='inherit'>
              <MoreIcon />
            </IconButton>
          </Box> */}
        </Toolbar>
      </AppBar>
      {/* {renderMobileMenu} */}
    </>
  );
};
export default AppHeader;
