import {
  FETCH_START,
  FETCH_SUCCESS,
  GET_ENABLED_SERVICES,
  GET_USER_BALANCE,
  GET_USER_COMMISSIONS,
  GET_USER_STATS,
  GET_USER_STATS_BYMONTH,
  SET_AUTH_TOKEN,
  SET_IS_FIRST_CONNECTION,
  SET_OPEN_CHANGE_PASSWORD_DIALOG,
  SET_RECIEVED_CODE_FROM_EMAIL,
  SHOW_MESSAGE,
  SIGNOUT_AUTH_SUCCESS,
  UPDATE_AUTH_USER,
  GET_ALL_REGIONS,
  FETCH_ERROR,
  GET_ALL_ACTIVITY,
} from '../../shared/constants/ActionTypes';
import jwtAxios from '../../@crema/services/auth/jwt-auth/jwt-api';
import {fetchError, fetchStart, fetchSuccess} from './Common';
import {appIntl} from '@crema/utility/Utils';
import dataAxios from '@crema/services/data/axiosData';

export const onJwtUserSignUp = (body) => {
  // //console.log('username, password', {username, password});
  const {messages} = appIntl();
  return async (dispatch) => {
    dispatch(fetchStart());
    try {
      await jwtAxios.post('/register', body).then((res) => {
        if (res.status === 201) {
          dispatch({type: FETCH_SUCCESS, payload: res.data.message});
        } else if (res.status === 200) {
          if (res.data.code === 2003) {
            if (res.data.message === 'Phone already exist') {
              dispatch(
                fetchError('Numéro téléphone existe déjà! Veuillez réessayer'),
              );
            } else {
              dispatch(fetchError('E-mail existe déjà! Veuillez réessayer'));
            }
          }
          if (res.data.code === 2001) {
            dispatch(
              fetchError(
                "Valeur d'une variable n'existe pas! Veuillez réessayer",
              ),
            );
          }
          if (res.data.code === 2002) {
            dispatch(fetchError('Type incorrect! Veuillez réessayer'));
          }
          if (res.data.code === 2005) {
            dispatch(fetchError('Erreur serveur! Veuillez réessayer'));
          }
        } else {
          dispatch(fetchError(messages['message.somethingWentWrong']));
        }
      });
    } catch (err) {
      dispatch(fetchError());
    }
  };
};

export const onJwtSignIn = ({username, password}, history) => {
  return async (dispatch) => {
    dispatch(fetchStart());
    const body = {username, password};
    try {
      await jwtAxios.post('/login', body).then((res) => {
        // console.log(res.data, 'login response');
        if (res.data.code === 2001) {
          dispatch(
            fetchError(
              "Nom d'utilisateur ou mot de passe est incorrect, Veuillez réessayer",
            ),
          );
        } else if (res.data.code === 2005) {
          dispatch(fetchError('Error serveur! Veuillez réessayer'));
        } else if (res.data.code === 2008) {
          dispatch(
            fetchError(
              "Nom d'utilisateur ou mot de passe est incorrect, Veuillez réessayer",
            ),
          );
        } else if (res.data.code === 2007) {
          dispatch(
            fetchError(
              "Votre compte est déactivé! Vous allez reçevoir un e-mail dès qu'il sera approuvé",
            ),
          );
        } else if (res.data.code === 2009) {
          dispatch(fetchError('Email non confirmé! Veuillez réessayer'));
        } else if (res.data.code === 2010) {
          dispatch(fetchError('Cet utilisateur est en process!'));
        } else if (res.data.code === 2011) {
          dispatch(fetchError('Cet utilisateur est déjà refusé!'));
        } else if (res.data.code === 2014) {
          dispatch(fetchError("Cet utilisateur n'est pas de role!"));
        } else if (
          res.data.role[0] !== 'ROLE_USER' &&
          res.data.role[0] !== 'ROLE_TOPNET' &&
          res.data.role[0] !== 'ROLE_PDV_VIP' &&
          res.data.role[0] !== 'ROLE_FOURNISSEUR'
        ) {
          dispatch(
            fetchError(
              "Vous n'etes pas autorisés à vous connecter à ce compte!",
            ),
          );
        } else {
          if (res.data.role[0] === 'ROLE_USER') {
            localStorage.setItem('user_id', res.data.profile.id_user);
            localStorage.setItem('CompanyId', res.data.profile.CompanyId);

            if (res.data.categoryWalletId) {
              localStorage.setItem('CategoryId', res.data.categoryWalletId.id);
            }
          } else if (res.data.role[0] === 'ROLE_PDV_VIP') {
            // localStorage.setItem('user_id', res.data.profile.Company.created_by);
            localStorage.setItem('user_id', res.data.profile.id_user);
            localStorage.setItem('CompanyId', res.data.profile.CompanyId);

            if (res.data.categoryWalletId) {
              localStorage.setItem('CategoryId', res.data.categoryWalletId.id);
            }
          } else if (res.data.role[0] == 'ROLE_FOURNISSEUR') {
            localStorage.setItem('user_id', res.data.profile.id_user);
          } else if (res.data.role === 'visitor') {
            localStorage.setItem('user_id', res.data.user.id);
          }
          localStorage.setItem('token', res.data.token.access_token);
          localStorage.setItem('expire_token', res.data.token.expires_in);
          localStorage.setItem('refresh_token', res.data.token.refresh_token);
          localStorage.setItem('first_name', res.data.user.firstname);
          localStorage.setItem('last_name', res.data.user.lastname);
          localStorage.setItem('phone', res.data.user.phone);
          localStorage.setItem('username', res.data.user.username);
          localStorage.setItem('email', res.data.user.email);

          localStorage.setItem('expires_in', res.data.token.expires_in);

          localStorage.setItem(
            'profilCompleted',
            res.data.user.profilCompleted,
          );
          localStorage.setItem('role', res.data.role[0]);
          dispatch(setJWTToken(res.data.token.access_token));
          dispatch(loadJWTUser());
          window.location.href('/dashboard');
        }
      });
      //username does not exist
    } catch (err) {
      ////console.log('err', err.response.data);
      if (err.response?.data.code === 1 || err.response?.data.code === 2001) {
        dispatch(
          fetchError(
            "Nom d'utilisateur ou mot de passe est incorrect! Veuillez réessayer avec un autre",
          ),
        );
      } else {
        dispatch(
          fetchError(
            'Vous ne pouvez pas accèder pour le moment! Veuillez réessayer plus tard',
          ),
        );
      }
    }
  };
};

export const loadJWTUser = () => {
  return async (dispatch) => {
    dispatch(fetchStart());
    try {
      const token = localStorage.getItem('token');
      const first_name = localStorage.getItem('first_name');
      const last_name = localStorage.getItem('last_name');
      const phone = localStorage.getItem('phone');
      const role = localStorage.getItem('role');
      const username = localStorage.getItem('username');
      const email = localStorage.getItem('email');
      const profilCompleted = localStorage.getItem('profilCompleted');
      dispatch(fetchSuccess());
      dispatch({
        type: UPDATE_AUTH_USER,
        payload: {
          authType: 'Bearer',
          displayName: first_name + ' ' + last_name,
          phone: phone,
          role: role,
          token: token,
          email: email,
          username: username,
          firstname: first_name,
          lastname: last_name,
          profilCompleted: profilCompleted,
        },
      });
    } catch (err) {
      //console.log('error!!!!', err);
      dispatch(fetchError(err.response.error));
    }
  };
};

export const setJWTToken = (token) => {
  return async (dispatch) => {
    dispatch({
      type: SET_AUTH_TOKEN,
      payload: token,
    });
  };
};

export const onJWTAuthSignout = () => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    setTimeout(() => {
      dispatch({type: SIGNOUT_AUTH_SUCCESS});
      dispatch({type: FETCH_SUCCESS});
      window.localStorage.clear();
    }, 500);
  };
};
export const getUserBalance = () => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .get('user-management/user/profile')
      .then((res) => {
        if (res.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          if (res.data.message === ' Retrieved!') {
            dispatch({
              type: GET_USER_BALANCE,
              payload: res.data.data.wallet.balance,
            });
            if (res.data.data?.user?.firstConnection === '0') {
              dispatch({type: SET_IS_FIRST_CONNECTION, payload: true});
            }
          }
        } else {
          dispatch(fetchError());
        }
      })
      .catch((error) => {
        dispatch(fetchError());
      });
  };
};

export const getUserStats = () => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .get('/stats/byUser')
      .then((res) => {
        //console.log('getstats services', res);
        if (res.status === 200) {
          dispatch({type: FETCH_SUCCESS});

          dispatch({
            type: GET_USER_STATS,
            payload: res.data.Services,
          });
          dispatch({
            type: GET_USER_STATS_BYMONTH,
            payload: res.data.Stats_by_month,
          });
        } else {
          dispatch(fetchError());
        }
      })
      .catch((error) => {
        dispatch(fetchError());
      });
  };
};
export const getUserCommissions = () => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .get('/wallet/stats-commission')
      .then((res) => {
        ////console.log('getstats services', res);
        if (res.status === 200) {
          // //console.log("resssdgehjkslmù",res.data.data.Services)
          dispatch({type: FETCH_SUCCESS});

          dispatch({
            type: GET_USER_COMMISSIONS,
            payload: res.data.data.Services,
          });
        } else {
          dispatch(fetchError());
        }
      })
      .catch((error) => {
        dispatch(fetchError());
      });
  };
};
export const onConfirmEmail = (params) => {
  const {messages} = appIntl();

  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .post('/registration-confirm', null, {params: params})
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: SHOW_MESSAGE,
            payload: 'Votre email a été vérifié',
          });
          window.location.href = '/connexion';
        } else {
          dispatch(fetchError(messages['message.somethingWentWrong']));
        }
      })
      .catch((error) => {
        dispatch(fetchError());
      });
  };
};

export const onForgetPassword = (params) => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .post('/forgot-password', params)
      .then((data) => {
        //console.log(data, 'usernamme');
        if (data.status === 201) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: SHOW_MESSAGE,
            payload:
              'Un message vous a été envoyer, merci de vérifier votre messagerie téléphonique.',
          });
        } else if (data.status === 200) {
          if (data.data.code === 2001)
            dispatch(
              fetchError(
                "Numéro de téléphone n'existe pas! Veuillez réessayer",
              ),
            );
        } else {
          dispatch(fetchError(messages['message.somethingWentWrong']));
        }
      })
      .catch((error) => {
        dispatch(fetchError("Numéro de téléphone n'existe pas"));
      });
  };
};

export const ongetRegions = (params) => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .get('/user-management/region', {params: params})
      .then((res) => {
        if (res.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: GET_ALL_REGIONS,
            payload: res.data.data.data,
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong'],
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR});
      });
  };
};

export const onGetActivity = () => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .get('/user-management/activity')
      .then((res) => {
        if (res.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: GET_ALL_ACTIVITY,
            payload: res.data.data.data,
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong'],
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR});
      });
  };
};

export const onContactPage = (params) => {
  const {messages} = appIntl();
  const body = params;
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .post('/user-management/contact', body)
      .then((data) => {
        // console.log(data, 'usernamme');
        if (data.status === 201) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: SHOW_MESSAGE,
            payload:
              'Nous avons bien reçu votre message et nous allons vous contacter le plus tôt possible. Merci pour votre confiance.',
          });
        } else {
          dispatch(fetchError(messages['message.somethingWentWrong']));
        }
      })
      .catch((error) => {
        dispatch(fetchError('Veuillez réessayer plus tard !!'));
      });
  };
};
export const onResetPassword = (params, token, username, history) => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .post(`/reset-password?username=${username}&token=${token}`, params)
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: SHOW_MESSAGE,
            payload: 'Votre mot de passe a été changé avec success',
          });
          window.location.href('/connexion');
        } else {
          dispatch(fetchError(messages['message.somethingWentWrong']));
        }
      })
      .catch((error) => {
        dispatch(fetchError());
      });
  };
};
export const onChangePassword = (params, setChangeDialogOpen) => {
  // //console.log('helloooooooooooo')
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .post('/change-password-code', params)
      .then((res) => {
        //console.log(res, 'change');
        if (res.status === 200) {
          if (res.data?.data === 2202) {
            dispatch({type: FETCH_SUCCESS});
            dispatch({
              type: SHOW_MESSAGE,
              payload: 'Un email a été envoyé!',
            });
            if (setChangeDialogOpen) {
              setChangeDialogOpen(false);
            } else {
              // dispatch({type: SET_OPEN_CHANGE_PASSWORD_DIALOG, payload: false});
              dispatch({type: SET_IS_FIRST_CONNECTION, payload: false});
            }
            dispatch({
              type: SET_RECIEVED_CODE_FROM_EMAIL,
              payload: {openDialog: true, ...params},
            });
          } else if (res.data?.code === 2008) {
            if (setChangeDialogOpen) {
              setChangeDialogOpen(false);
            }
            dispatch(
              fetchError('Mot de passe saisi est invalide! Veuillez réessayer'),
            );

            dispatch({type: SET_OPEN_CHANGE_PASSWORD_DIALOG, payload: false});
          }
        } else {
          dispatch(fetchError(messages['message.somethingWentWrong']));
          dispatch({type: SET_OPEN_CHANGE_PASSWORD_DIALOG, payload: false});
        }
      })
      .catch((error) => {
        // console.log(error);
        dispatch(fetchError());
      });
  };
};
export const onSendCodeByEmail = (params, fromDialog) => {
  // //console.log('helloooooooooooo')
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .post('/change-password', params)
      .then((data) => {
        //console.log(data, 'change');
        if (data.status === 200) {
          if (data.data.code === 2202) {
            dispatch({type: FETCH_SUCCESS});
            dispatch({
              type: SHOW_MESSAGE,
              payload: 'Votre Mot de pass a été changé avec succès!',
            });
            dispatch({type: SET_RECIEVED_CODE_FROM_EMAIL, payload: false});
          } else if (data.data.code === 2015) {
            dispatch(
              fetchError('Le code saisi est invalide Veuillez réessayer!'),
            );
          } else if (data.data.code === 2018) {
            if (fromDialog) {
              dispatch({type: SET_IS_FIRST_CONNECTION, payload: true});
            }
            dispatch({type: SET_RECIEVED_CODE_FROM_EMAIL, payload: false});

            dispatch(
              fetchError('Le code saisi a été expiré Veuillez réessayer!'),
            );
          }
        } else {
          dispatch(fetchError('Le code saisi est invalide Veuillez réessayer'));

          dispatch({type: SET_RECIEVED_CODE_FROM_EMAIL, payload: false});
        }
      })
      .catch((error) => {
        dispatch(fetchError());

        dispatch({type: SET_RECIEVED_CODE_FROM_EMAIL, payload: false});
      });
  };
};
export const onSearchExistance = (prop, value) => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .get('/search', {params: {[prop]: value}})
      .then((res) => {
        if (res.status === 200) {
          ////console.log(res);
          dispatch({type: FETCH_SUCCESS});
          if (res.data.code === 2003) {
            if (prop === 'email') {
              return false;
            } else if (prop === 'phone' || prop === 'username') {
              return false;
            }
          } else {
            return true;
          }
        }
      })
      .catch((error) => {
        dispatch(fetchError());
      });
  };
};
export const onGetEnabledServices = (prop, value) => {
  // const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .get('/verif/service')
      .then((res) => {
        if (res.status === 200) {
          ////console.log(res);
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: GET_ENABLED_SERVICES,
            payload: res.data.data.service,
          });
        }
      })
      .catch((error) => {
        dispatch(fetchError());
      });
  };
};
