import React from 'react';

export const authRouteConfig = [
  {
    routes: [
      {
        path: '/connexion',
        component: React.lazy(() => import('./Signin/index')),
      },
      {
        path: '/com/connexion',
        component: React.lazy(() => import('./Signin/Singinblog')),
      },
      {
        path: '/ar/connexion',
        component: React.lazy(() => import('./Signin/Singinarabe')),
      },
      {
        path: '/com/blogs',
        component: React.lazy(() => import('./Signin/pagewoelcom')),
      },
      {
        exact: true,
        path: '/com/blogs/Edit-Blog/:id',
        // path: '/Liste-Active/Edit-Blog/:id',
        component: React.lazy(() => import('../blogAccount/ModifyBlog')),
      },
      /*{
        path: '/blog',
        component: React.lazy(() => import('../welcomePage/ContactPage')),
      },*/
      {
        path: '/reset-password',
        component: React.lazy(() =>
          import('./ResetPassword/ResetPasswordJwtAuth'),
        ),
      },
      {
        path: '/registration-confirm',
        component: React.lazy(() => import('./ConfirmAccount/ConfirmEmail')),
      },
      {
        path: '/inscription',
        component: React.lazy(() => import('./Signup/index')),
      },
      {
        path: '/ar/inscription',
        component: React.lazy(() => import('./Signup/index_arabe')),
      },
      {
        path: '/resultat',
        component: React.lazy(() =>
          import('./Signup/CheckoutSuccess/CheckoutSuccess'),
        ),
      },
      {
        path: '/ar/resultat',
        component: React.lazy(() =>
          import('./Signup/CheckoutSuccess/CheckoutSuccessArabe'),
        ),
      },
      {
        path: '/home.html',
        component: React.lazy(() => import('./welcomePage/index')),
      },
      {
        exact: true,
        path: '/contactez',
        component: React.lazy(() => import('./welcomePage/ContactPage')),
      },
      {
        exact: true,
        path: '/ar/contactez',
        component: React.lazy(() =>
          import('./welcomePage/ContactPageArabe.js'),
        ),
      },
      {
        exact: true,
        path: '/FAQ',
        component: React.lazy(() => import('./welcomePage/FAQPage')),
      },
      {
        exact: true,
        path: '/politique-de-confidentialité',
        component: React.lazy(() => import('./welcomePage/PolitiquePage')),
      },

      {
        path: '/mot-de-passe-oublie',
        component: React.lazy(() => import('./ForgetPassword')),
      },
      {
        path: '/mot-de-passe-oublie-ar',
        component: React.lazy(() =>
          import('./ForgetPassword/ForgetPasswordJwtAuthArabe'),
        ),
      },
      {
        path: '/privacy-policy',
        component: React.lazy(() => import('./welcomePage/Conditions')),
      },
      {
        exact: true,
        path: '/guide',
        component: React.lazy(() =>
          import('./welcomePage/guideMintroute/index'),
        ),
      },
      {
        exact: true,
        path: '/guide/:id',
        component: React.lazy(() =>
          import('./welcomePage/guideMintroute/details'),
        ),
      },
      {
        path: '/error-pages/error-404',
        component: React.lazy(() => import('../errorPages/Error404/index')),
      },
    ],
  },
];
