import React from 'react';
import {Redirect} from 'react-router-dom';
import {createRoutes} from '../@crema/utility/Utils';
import {errorPagesConfigs} from './errorPages';
import {authRouteConfig} from './auth';
import {initialUrl} from '../shared/constants/AppConst';
import {homePageConfig} from './home';

import {teamPagesConfig} from './invoicePayment';
import {rechargePagesConfig} from './recharge';
import {rechargeCardPagesConfig} from './rechargeCard';
import {rechargeWalletPagesConfig} from './rechargeWallet';
import {FeedAccountPagesConfig} from './FeedAccount';
import {parametersPageConfig} from './Parameters';
import {raccourciPagesConfig} from './Raccourci';
import {couponsPagesConfig} from './Coupons';
import {hotelsPageConfig} from './Hotels';
import {BookingRoute} from './booking';

import {reclamationPageConfig} from './reclamation';
import {orderPagesConfig} from './CommandVoucher';
import {topnetPageConfig} from './transactionsTopnet';
import {vipPageConfig} from './transactionsTopupVip';
import {fournisseurConfig} from './historyFournisor';

const routeConfigs = [
  ...errorPagesConfigs,
  ...authRouteConfig,
  ...FeedAccountPagesConfig,
  ...homePageConfig,
  ...rechargePagesConfig,
  ...teamPagesConfig,
  ...rechargeCardPagesConfig,
  ...parametersPageConfig,
  ...raccourciPagesConfig,
  ...rechargeWalletPagesConfig,
  ...couponsPagesConfig,
  ...hotelsPageConfig,
  ...reclamationPageConfig,
  ...orderPagesConfig,
  ...topnetPageConfig,
  ...vipPageConfig,
  ...fournisseurConfig,
  ...BookingRoute,
];

const routes = [
  ...createRoutes(routeConfigs),
  {
    path: '/',
    exact: true,
    component: () => <Redirect to={'/dashboard'} />,
  },
  {
    component: () => <Redirect to='/' />,
  },
];

export default routes;
