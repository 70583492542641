import React from 'react';
import {authRole} from 'shared/constants/AppConst';

export const rechargeCardPagesConfig = [
  {
    auth: authRole.user,
    routes: [
      {
        exact: true,
        path: '/recharge/carte',
        component: React.lazy(() => import('./Pages/RechargeMenu')),
      },
    ],
  },
  {
    auth: authRole.user,
    routes: [
      {
        exact: true,
        path: '/Historique-rechargeCarte',
        component: React.lazy(() =>
          import('./Pages/history/HistoryFeedCardWallet'),
        ),
      },
    ],
  },
  {
    auth: authRole.user,
    routes: [
      {
        exact: true,
        path: '/Menu-recharge/poste',
        component: React.lazy(() => import('./Pages/rechargeCard')),
      },
    ],
  },
];
