import React from 'react';
import {authRole} from 'shared/constants/AppConst';

export const hotelsPageConfig = [
  {
    auth: authRole.user,
    routes: [
      {
        path: '/Hotels',
        component: React.lazy(() => import('./Pages/index')),
      },
      {
        //path: '/Hotel&:index&:pax&:cityId',
        path: '/Hotel/:id',
        component: React.lazy(() =>
          import('./Pages/Products/ProductListing/ProductList/ListItem'),
        ),
      },
      {
        path: '/Hotel-details',
        component: React.lazy(() => import('./Pages/Products/quote')),
      },
      {
        path: '/Hotel-Historique',
        component: React.lazy(() => import('./Pages/History')),
      },
    ],
  },
];
