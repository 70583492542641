import React from 'react';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import {useIntl} from 'react-intl';
import {Link} from 'react-router-dom';
import IntlMessages from '@crema/utility/IntlMessages';
import 'shared/styles/buttonStyle.css';
import {Box} from '@material-ui/core';
// const ColorButton = withStyles((theme) => ({
//   root: {
//     color: '#FBFBFB',
//     backgroundColor: '#003255',
//     fontWeight: 'normal',
//     '&:hover': {
//       color: '#003255',
//       backgroundColor: '#0032550a',
//       border: 'solid',
//       borderColor: '#003255',
//       borderWidth: '1px',
//     },
//   },
// }))(Button);
// const useStyles = makeStyles((theme) => ({
//   margin: {
//     font: 'italic normal 900 20px Poppins;',
//     textDecoration: 'none',
//     padding: '10px 20px 10px 20px ',
//     marginRight: theme.spacing(10),
//     // marginBottom: '10px',
//     marginTop: '10px',
//     [theme.breakpoints.down('md')]: {
//       fontSize: '16px',
//       padding: '15px 20px 15px 20px ',

//       marginRight: theme.spacing(1),
//       //marginTop: theme.spacing(1),
//       width: '150px',
//     },
//   },
//   text: {
//     fontSize: '20px',
//     fontWeight: 'bolder',
//   },
// }));

export default function TotalWallet() {
  //const {messages} = useIntl();
  //const classes = useStyles();
  return (
    <div>
      <Link style={{textDecoration: 'none'}} to={'/Menu-alimentation'}>
        <Box className='animated-button1'>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <IntlMessages id='common.feed.account.btn' />
        </Box>
      </Link>
    </div>
  );
}
