const languageData = [
  {
    languageId: 'french',
    locale: 'fr',
    name: 'Français',
    icon: 'fr',
  },
  // {
  //   languageId: 'chinese',
  //   locale: 'zh',
  //   name: 'Chinese',
  //   icon: 'cn',
  // },
  // {
  //   languageId: 'spanish',
  //   locale: 'es',
  //   name: 'Spanish',
  //   icon: 'es',
  // },
  {
    languageId: 'english',
    locale: 'en',
    name: 'English',
    icon: 'us',
  },
  // {
  //   languageId: 'italian',
  //   locale: 'it',
  //   name: 'Italian',
  //   icon: 'it',
  // },
  {
    languageId: 'saudi-arabia',
    locale: 'ar',
    name: 'العربية',
    icon: 'sa',
  },
];
export default languageData;
