import {
  GET_ALL_COMMERCIALS,
  GET_ALL_FEED_TYPES,
  GET_ALL_HISTORY_VIREMENT,
} from '../../shared/constants/ActionTypes';

const initialSettings = {
  historyViremnt: [],
  commercialList: [],
  feedType: [],
  totalPages: 0,
};

const alimentationCompteReducer = (state = initialSettings, action) => {
  switch (action.type) {
    case GET_ALL_HISTORY_VIREMENT: {
      return {
        ...state,
        historyViremnt: action.payload.items ? action.payload.items : [],
        totalPages: action.payload.totalPages,
      };
    }
    case GET_ALL_FEED_TYPES: {
      return {
        ...state,
        feedType: action.payload,
      };
    }
    case GET_ALL_COMMERCIALS: {
      return {
        ...state,
        commercialList: action.payload.data,
      };
    }
    default:
      return state;
  }
};

export default alimentationCompteReducer;
