import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import ReactLoading from 'react-loading';

import Box from '@material-ui/core/Box';
import {makeStyles} from '@material-ui/core';
const useStyles = makeStyles((theme) => ({
  mainItem: {
    backgroundColor: '#8a8a8a1a',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    position: 'fixed',
    //top: -100,
    left: 0,
    zIndex: 500,
    right: 0,
    bottom: 0,
  },
  waitingMessage: {
    //zIndex='200000'
    fontSize: '25px',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#ffffff',
    color: 'black',
    borderRadius: '13px',
    animation: '3s linear 1s infinite running slidein',
    padding: '50px',
  },
}));
const LoaderInfo = (props) => {
  const {handleMessage} = props;
  const classes = useStyles();
  return (
    <Box>
      <Box className={classes.mainItem}>
        <Box className={classes.waitingMessage}>
          {/* <CircularProgress /> */}
          <ReactLoading
            type={'spin'}
            color='#003250'
            width='90px'
            heignt='70px'
          />
          {handleMessage}
        </Box>
      </Box>
    </Box>
  );
};

export default LoaderInfo;
