import dataAxios from '@crema/services/data/axiosData';
import {appIntl} from '@crema/utility/Utils';
import {
  FETCH_START,
  FETCH_SUCCESS,
  GET_INTERVENANT,
  GET_SUPPLIER,
  POST_CATEGORY,
  SHOW_MESSAGE,
  GET_SERVICE,
  GET_REIMBURSED,
  GET_CATEGORY,
  GET_ALL_SUPPLIER_SERVICE,
  GET_SERVICE_BY_ID,
  GET_POSTE_BY_ID,
  GET_WALLET_BY_ID,
  GET_TOPUP_BY_ID,
  CREATE_OBJECT_FROM_SERV_SUPP,
  GET_TYPE,
  GET_ACTIVITY,
  GET_CATEGORY_PROFILE,
  GET_TYPE_POS,
  GET_USER_PROFILE,
  GET_EXTRACT,
  GET_EXTRACT_TO_PDF,
  GET_CANALS,
  GET_PROFILE_PIC,
  SET_IS_FIRST_CONNECTION,
  SHOW_MESSAGE_SUBSCRIPTION,
  GET_CA,
  GET_ALL_SERVICE_RECLAMATION,
  GET_ALL_RECLAMATION_BY_USER,
  GET_ALL_COMMENTS_RECLAMATION,
  ID_RECLAMATION,
  GET_DOCUMENTS_C_EMPTY,
  GET_DOCUMENTS_P_EMPTY,
  GET_DOCUMENTS_C,
  GET_DOCUMENTS_P,
  FETCH_ERROR,
} from 'shared/constants/ActionTypes';
import {baseImgbUrl, exportFiles} from 'shared/constants/AppConst';
import {fetchError, fetchStart} from './Common';

export const postNewComment = (body) => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .post('/feedback/comment', body)
      .then((res) => {
        if (res.status === 201) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: SHOW_MESSAGE,
            payload: res.data.message,
          });
        } else {
          dispatch(fetchError(messages['message.somethingWentWrong']));
        }
      })
      .catch((error) => {
        dispatch(fetchError());
      });
  };
};

export const onGetAllCommentsforReclamation = (id, params) => {
  const {messages} = appIntl();

  return (dispatch) => {
    dispatch({
      type: ID_RECLAMATION,
      payload: id,
    });
    dispatch({type: FETCH_START});
    dataAxios
      .get('/feedback/comment?reclamationid=' + id, {params: params})
      .then((res) => {
        if (res.status === 200) {
          if (res?.data.status === 'success') {
            dispatch({type: FETCH_SUCCESS});
            if (res.data.message !== 'Comments retrieved') {
              dispatch({
                type: GET_ALL_COMMENTS_RECLAMATION,
                payload: {data: [], totalPages: 0},
              });
            } else {
              dispatch({
                type: GET_ALL_COMMENTS_RECLAMATION,
                payload: res.data.data,
              });
            }
          } else {
            dispatch({type: FETCH_SUCCESS});
          }
        } else {
          dispatch(fetchError(messages['message.somethingWentWrong']));
        }
      })
      .catch((error) => {
        dispatch(fetchError());
      });
  };
};

export const onCreateService = (service) => {
  const {messages} = appIntl();
  return (dispatch) => {
    const body = service;
    dispatch({type: FETCH_START});
    dataAxios
      .post('/wallet/service', body)
      .then((data) => {
        if (data.status === 201) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: SHOW_MESSAGE,
            payload: data.data.message,
          });
        } else {
          dispatch(fetchError(messages['message.somethingWentWrong']));
        }
      })
      .catch((error) => {
        dispatch(fetchError());
      });
  };
};
export const onCreateCategory = (service) => {
  const {messages} = appIntl();
  return (dispatch) => {
    const body = service;
    dispatch({type: FETCH_START});
    dataAxios
      .post('/wallet/category', body)
      .then((res) => {
        if (res.status === 201) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: POST_CATEGORY,
            payload: res.data,
          });
          dispatch({
            type: SHOW_MESSAGE,
            payload: res.data.message,
          });
        } else {
          dispatch(fetchError(messages['message.somethingWentWrong']));
        }
      })
      .catch((error) => {
        dispatch(fetchError());
      });
  };
};
export const onCreateIntervenant = (service) => {
  const {messages} = appIntl();
  return (dispatch) => {
    const body = service;
    dispatch({type: FETCH_START});
    dataAxios
      .post('/wallet/intervenant', body)
      .then((res) => {
        if (res.status === 201) {
          dispatch({type: FETCH_SUCCESS});
          // //console.log('intervenant dataaaaaaaaa', res);

          dispatch({
            type: SHOW_MESSAGE,
            payload: res.data.message,
          });
        } else {
          dispatch(fetchError(messages['message.somethingWentWrong']));
        }
      })
      .catch((error) => {
        dispatch(fetchError());
      });
  };
};
export const onCreateSupplier = (supplier) => {
  const {messages} = appIntl();
  return (dispatch) => {
    const body = supplier;
    dispatch({type: FETCH_START});
    dataAxios
      .post('/wallet/supplier', body)
      .then((data) => {
        if (data.status === 201) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: SHOW_MESSAGE,
            payload: data.data.message,
          });
        } else {
          dispatch(fetchError(messages['message.somethingWentWrong']));
        }
      })
      .catch((error) => {
        dispatch(fetchError());
      });
  };
};
export const onCreateServiceSupplier = (supplier) => {
  const {messages} = appIntl();
  return (dispatch) => {
    const body = supplier;
    dispatch({type: FETCH_START});
    dataAxios
      .post('wallet/supplier-service', body)
      .then((data) => {
        if (data.status === 201) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: SHOW_MESSAGE,
            payload: data.data.message,
          });
        } else {
          dispatch(fetchError(messages['message.somethingWentWrong']));
        }
      })
      .catch((error) => {
        dispatch(fetchError());
      });
  };
};
export const onGetService = (params) => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .get('/wallet/service', {params: params})
      .then((res) => {
        if (res.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: GET_SERVICE,
            payload: res.data.data,
          });
        } else {
          dispatch(fetchError(messages['message.somethingWentWrong']));
        }
      })
      .catch((error) => {
        dispatch(fetchError());
      });
  };
};

export const onGetAllIntervenant = () => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .get('/wallet/intervenant')
      .then((res) => {
        if (res.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: GET_INTERVENANT,
            payload: res.data.data.data,
          });
        } else {
          dispatch(fetchError(messages['message.somethingWentWrong']));
        }
      })
      .catch((error) => {
        dispatch(fetchError());
      });
  };
};
export const onGetAllSuppliers = () => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .get('/wallet/supplier')
      .then((res) => {
        // //console.log('suppliers data', res);
        if (res.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: GET_SUPPLIER,
            payload: res.data.data.data,
          });
        } else {
          dispatch(fetchError(messages['message.somethingWentWrong']));
        }
      })
      .catch((error) => {
        dispatch(fetchError());
      });
  };
};

export const onGetAllReimbursed = () => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .get('/wallet/reimbursed')
      .then((res) => {
        if (res.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: GET_REIMBURSED,
            payload: res.data.data.data,
          });
        } else {
          dispatch(fetchError(messages['message.somethingWentWrong']));
        }
      })
      .catch((error) => {
        dispatch(fetchError());
      });
  };
};
export const onCreateCompany = (company, history, setSubmit) => {
  const {messages} = appIntl();
  let timeout = 0;
  return (dispatch) => {
    const body = company;
    dispatch({type: FETCH_START});
    dataAxios
      .post('/register', body)
      .then((data) => {
        if (data.status === 201) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: SHOW_MESSAGE_SUBSCRIPTION,
            payload:
              "Votre demande a été envoyée! Vérifiez votre boite d'e-mail",
          });
          setTimeout(() => {
            history.push('/resultat');
          }, 2500);

          setSubmit(true);
        } else {
          if (data.data.code === '2003') {
            if (data.data.error === 'Phone already exist') {
              dispatch(fetchError('Numéro de téléphone existe déjà'));
            } else if (data.data.error === 'email already exist') {
              dispatch(
                fetchError('Email saisi existe déjà Veuillez réessayer!'),
              );
            } else {
              dispatch(
                fetchError(
                  "Nom d'utilisateur saisi existe déjà Veuillez réessayer!",
                ),
              );
            }

            setSubmit(false);
          } else if (data.data.code === '2007') {
            dispatch(fetchError('Cet utilisateur est déactivé!'));
          } else if (data.data.code === '2008') {
            dispatch(
              fetchError(
                'Le format de mot de passe est invalide! Veuillez réessayer',
              ),
            );
          } else if (data.data.code === '2009') {
            dispatch(
              fetchError("L'e-mail que vous avez utilisé n'est pas confirmé!"),
            );
          } else {
            dispatch(fetchError(data.data.code));
          }
        }
      })
      .catch((error) => {
        if (error?.response?.data?.error?.code === 2003) {
          dispatch(fetchError('Veuillez vérifer vos données'));
        } else {
          dispatch(fetchError(error?.response?.data?.error?.message));
        }
      });
  };
};
export const onCreateCompanyArabe = (company, history, setSubmit) => {
  const {messages} = appIntl();
  let timeout = 0;
  return (dispatch) => {
    const body = company;
    dispatch({type: FETCH_START});
    dataAxios
      .post('/register', body)
      .then((data) => {
        if (data.status === 201) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: SHOW_MESSAGE_SUBSCRIPTION,
            payload: 'تم ارسال طلبك! تحقق من صندوق البريد الإلكتروني الخاص بك',
          });
          setTimeout(() => {
            history.push('/ar/resultat');
          }, 2500);

          setSubmit(true);
        } else {
          if (data.data.code === '2003') {
            if (data.data.error === 'Phone already exist') {
              dispatch(fetchError('رقم الهاتف موجود'));
            } else if (data.data.error === 'email already exist') {
              dispatch(
                fetchError(
                  'البريد الإلكتروني الذي تم إدخاله موجود, يرجى المحاولة مرة أخرى!',
                ),
              );
            } else {
              dispatch(
                fetchError(
                  'اسم المستخدم الذي تم إدخاله موجود, يرجى المحاولة مرة أخرى!',
                ),
              );
            }

            setSubmit(false);
          } else if (data.data.code === '2007') {
            dispatch(fetchError('هذا المستخدم معطل!'));
          } else if (data.data.code === '2008') {
            dispatch(
              fetchError(' كلمة المرور غير مقبولة! المرجو اعادة المحاولة'),
            );
          } else if (data.data.code === '2009') {
            dispatch(
              fetchError('البريد الإلكتروني الذي استخدمته لم يتم تأكيده!'),
            );
          } else {
            dispatch(fetchError(data.data.code));
          }
        }
      })
      .catch((error) => {
        if (error?.response?.data?.error?.code === 2003) {
          dispatch(fetchError('يرجى التحقق من البيانات الخاصة بك'));
        } else {
          dispatch(fetchError(error?.response?.data?.error?.message));
        }
      });
  };
};
export const onGetAllCategories = () => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .get('/wallet/category')
      .then((res) => {
        if (res.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: GET_CATEGORY,
            payload: res.data.data.data,
          });
        } else {
          dispatch(fetchError(messages['message.somethingWentWrong']));
        }
      })
      .catch((error) => {
        dispatch(fetchError());
      });
  };
};
export const onGetAllSerSupp = () => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch(fetchStart());
    try {
      let params = {size: 1000};
      dataAxios
        .get('wallet/supplier-service', {params: params})
        .then((data) => {
          if (data.status === 200) {
            dispatch({type: FETCH_SUCCESS});
            if (data.data.message === 'ServiceSupplier retrieved') {
              dispatch({
                type: GET_ALL_SUPPLIER_SERVICE,
                payload: data.data.data.items,
              });
              dispatch({
                type: CREATE_OBJECT_FROM_SERV_SUPP,
                payload: data.data.data.items,
              });
            } else {
              dispatch(fetchError('Serivce supplier non disponible'));
            }
          } else {
            dispatch(fetchError(messages['message.somethingWentWrong']));
          }
        });
    } catch (err) {
      dispatch(fetchError(err));
    }
  };
};
export const onGetServiceById = (id) => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .get('/wallet/service/' + id)
      .then((res) => {
        if (res.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: GET_SERVICE_BY_ID,
            payload: res.data.data.data,
          });
        } else {
          dispatch(fetchError(messages['message.somethingWentWrong']));
        }
      })
      .catch((error) => {
        dispatch(fetchError());
      });
  };
};
export const onGetTopupById = (id) => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .get('/wallet/service/' + id)
      .then((res) => {
        if (res.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: GET_TOPUP_BY_ID,
            payload: res.data.data.data,
          });
        } else {
          dispatch(fetchError(messages['message.somethingWentWrong']));
        }
      })
      .catch((error) => {
        dispatch(fetchError());
      });
  };
};
export const onGetWalletById = (id) => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .get('/wallet/service/' + id)
      .then((res) => {
        if (res.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: GET_WALLET_BY_ID,
            payload: res.data.data.data,
          });
        } else {
          dispatch(fetchError(messages['message.somethingWentWrong']));
        }
      })
      .catch((error) => {
        dispatch(fetchError());
      });
  };
};
export const onGetPosteById = (id) => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .get('/wallet/service/' + id)
      .then((res) => {
        if (res.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: GET_POSTE_BY_ID,
            payload: res.data.data.data,
          });
        } else {
          dispatch(fetchError(messages['message.somethingWentWrong']));
        }
      })
      .catch((error) => {
        dispatch(fetchError());
      });
  };
};
export const onGetActivity = () => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .get('/user-management/activity')
      .then((res) => {
        if (res.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: GET_ACTIVITY,
            payload: res.data.data.data,
          });
        } else {
          dispatch(fetchError(messages['message.somethingWentWrong']));
        }
      })
      .catch((error) => {
        dispatch(fetchError());
      });
  };
};
export const onGetCanals = () => {
  const {messages} = appIntl();
  return (dispatch) => {
    dataAxios
      .get('/user-management/type-canal')
      .then((res) => {
        if (res.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: GET_CANALS,
            payload: res.data.data.data,
          });
        } else {
          dispatch(fetchError(messages['message.somethingWentWrong']));
        }
      })
      .catch((error) => {
        dispatch(fetchError());
      });
  };
};
export const onGetAllTypePDV = () => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .get('/user-management/type-user')
      .then((res) => {
        if (res.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: GET_TYPE_POS,
            payload: res.data.data.data,
          });
        } else {
          dispatch(fetchError(messages['message.somethingWentWrong']));
        }
      })
      .catch((error) => {
        dispatch(fetchError());
      });
  };
};

export const onGetCategoriesProfile = () => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .get('/user-management/category')
      .then((res) => {
        if (res.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: GET_CATEGORY_PROFILE,
            payload: res.data.data.data,
          });
        } else {
          dispatch(fetchError(messages['message.somethingWentWrong']));
        }
      })
      .catch((error) => {
        dispatch(fetchError());
      });
  };
};
export const onGetUserProfile = () => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .get('/user-management/user/profile')
      .then((res) => {
        if (res.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: GET_USER_PROFILE,
            payload: res.data.data,
          });
          if (res.data.data.profile.Image) {
            dispatch(
              onUploadImages(
                {
                  url: res.data.data.profile.Image.path?.slice(1),
                  port: baseImgbUrl,
                },
                'profile',
              ),
            );
          }
        } else {
          dispatch(fetchError(messages['message.somethingWentWrong']));
        }
      })
      .catch((error) => {
        dispatch(fetchError());
      });
  };
};

export const onGetOneCompany = () => {
  const {messages} = appIntl();

  return (dispatch) => {
    dispatch({type: FETCH_START});

    dataAxios
      .get('/user-management/user/profile')
      .then((res) => {
        if (res.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: GET_DOCUMENTS_C_EMPTY,
          });
          dispatch({
            type: GET_DOCUMENTS_P_EMPTY,
          });
          dispatch({
            type: GET_USER_PROFILE,
            payload: res.data.data,
          });

          let a = res.data.data.profile?.Company.documentsC;
          // console.log("aaaaaaaaaaa",a);
          if (a?.length > 0) {
            for (let k = 0; k < a.length; k++) {
              dispatch(
                onUploadImages(
                  {url: a[k].file?.slice(1), port: baseImgbUrl},
                  'documentC',
                ),
              );
            }
          }
          let g = res.data.data.profile?.Company.images;
          //console.log(g, 'gallery');
          if (g?.length > 0) {
            for (let m = 0; m < g.length; m++) {
              dispatch(
                onUploadImages(
                  {url: g[m].path?.slice(1), port: baseImgbUrl},
                  'gallerie',
                ),
              );
            }
          }
          let b = res.data.data.profile?.documentsP;
          //console.log("bbbbbbbb",b);
          if (b?.length > 0) {
            for (let k = 0; k < b.length; k++) {
              dispatch(
                onUploadImages(
                  {url: b[k].file?.slice(1), port: baseImgbUrl},
                  'documentP',
                ),
              );
            }
          }
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong'],
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR});
      });
  };
};

export const onGetAllExtracts = (id, params) => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .get('/user-management/wallet/extrait', {params: params})
      .then((res) => {
        if (res.status === 200) {
          if (res.data.code === 2203) {
            dispatch({type: FETCH_SUCCESS});
            dispatch({
              type: GET_EXTRACT,
              payload: {
                items: [],
                totalPages: 0,
              },
            });
          } else {
            dispatch({type: FETCH_SUCCESS});
            dispatch({
              type: GET_EXTRACT,
              payload: res.data.data,
            });
          }
        } else {
          dispatch(fetchError(messages['message.somethingWentWrong']));
        }
      })
      .catch((error) => {
        dispatch(fetchError());
      });
  };
};
export const getAllExtractDatatoPDF = (params) => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .get('/wallet/history/pdf', {params: params})
      .then((res) => {
        if (res.status === 200) {
          if (res.data.message === 'No History found') {
            dispatch({type: FETCH_SUCCESS});
            dispatch({
              type: GET_EXTRACT_TO_PDF,
            });
          } else {
            dispatch({type: FETCH_SUCCESS});
            window.open(exportFiles + res.data.data.link.slice(1), '_blank');
          }
        } else {
          dispatch(fetchError(messages['message.somethingWentWrong']));
        }
      })
      .catch((error) => {
        dispatch(fetchError());
      });
  };
};
export const onContactUser = (body) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .post('/feedback/reclamation', body)
      .then((data) => {
        if (data.status === 201) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: SHOW_MESSAGE,
            payload:
              'Nous avons bien reçu votre reclamation et nous allons vous contacter le plus tôt possible. Merci pour votre confiance.',
          });
        } else {
          dispatch(
            fetchError(
              'Vous ne pouvez pas envoyer cette demande pour le moment !!',
            ),
          );
        }
      })
      .catch((error) => {
        dispatch(fetchError());
      });
  };
};
export const onGetAllServices = () => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .get('/feedback/service')
      .then((res) => {
        if (res.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: GET_ALL_SERVICE_RECLAMATION,
            payload: res.data.data,
          });
        } else {
          dispatch(fetchError(messages['message.somethingWentWrong']));
        }
      })
      .catch((error) => {
        dispatch(fetchError());
      });
  };
};

export const onGetAllReclamationByUser = (param) => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .get('/feedback/reclamation', {params: param})
      .then((res) => {
        if (res.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: GET_ALL_RECLAMATION_BY_USER,
            payload: res.data.data,
          });
        } else {
          dispatch(fetchError(messages['message.somethingWentWrong']));
        }
      })
      .catch((error) => {
        dispatch(fetchError());
      });
  };
};

export const onUploadImages = (params, value) => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .post('/upload', params)
      .then((res) => {
        if (res.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          if (res.data.path) {
            if (value === 'documentC') {
              dispatch({
                type: GET_DOCUMENTS_C,
                payload: res.data.path,
              });
            } else if (value === 'documentP') {
              dispatch({
                type: GET_DOCUMENTS_P,
                payload: res.data.path,
              });
            } else if (value === 'profile') {
              dispatch({
                type: GET_PROFILE_PIC,
                payload: res.data.path,
              });
            }
          } else {
            dispatch(fetchError(res.data.path));
          }
        } else {
          dispatch(fetchError(messages['message.somethingWentWrong']));
        }
      })
      .catch((error) => {
        dispatch(
          fetchError('Vous ne pouvez générer le document pour le moment'),
        );
      });
  };
};

export const onGetCa = (id, params) => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .get('/wallet/ca/company/' + id, {params: params})
      .then((res) => {
        if (res.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: GET_CA,
            payload: res.data.data,
          });
        } else {
          dispatch(fetchError(messages['message.somethingWentWrong']));
        }
      })
      .catch((error) => {
        dispatch(fetchError());
      });
  };
};
