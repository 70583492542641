import React from 'react';
import {authRole} from 'shared/constants/AppConst';

export const BookingRoute = [
  {
    auth: authRole.user,
    routes: [
      {
        path: '/Booking',
        component: React.lazy(() => import('./pages/search')),
      },
      {
        path: '/Reservation',
        component: React.lazy(() => import('./pages/reservation')),
      },
      {
        path: '/Hotel',
        component: React.lazy(() => import('./pages/hotel')),
      },
      {
        path: '/Request',
        component: React.lazy(() => import('./pages/request')),
      },
      {
        path: '/Liste',
        component: React.lazy(() => import('./pages/liste')),
      },
      {
        path: '/Historique Reservation',
        component: React.lazy(() => import('./pages/history')),
      },
    ],
  },
];
