import React from 'react';
import {authRole} from 'shared/constants/AppConst';

export const FeedAccountPagesConfig = [
  {
    auth: authRole.vipUser,
    routes: [
      {
        exact: true,
        path: '/Menu-alimentation',
        component: React.lazy(() => import('./Pages/menu')),
      },
      {
        exact: true,
        path: '/Menu-alimentation/Virement',
        component: React.lazy(() => import('./Pages/AlimentationCompte')),
      },
      {
        exact: true,
        path: '/Demande_Remboursement',
        component: React.lazy(() => import('../Remboursement/demande')),
      },
      {
        exact: true,
        path: '/Historique_Remboursement',
        component: React.lazy(() => import('../Remboursement/history')),
      },

      {
        exact: true,
        path: '/Menu-alimentation/Versement',
        component: React.lazy(() => import('./Pages/AlimentationCompte')),
      },
      {
        exact: true,
        path: '/Menu-alimentation/Espèce',
        component: React.lazy(() => import('./Pages/AlimentationCompte')),
      },

      {
        exact: true,
        path: '/Menu-alimentation/Banque',
        component: React.lazy(() => import('./Pages/BankComp')),
      },
      {
        exact: true,
        path: '/success',
        component: React.lazy(() => import('./Pages/successClictopay')),
      },
      {
        exact: true,
        path: '/failure',
        component: React.lazy(() => import('./Pages/failureClictopay')),
      },
    ],
  },
];
