import {
  POST_RECLAMATION_FAIL,
  POST_RECLAMATION_SUCCESS,
  GET_SERVICES,
  GET_PERIORITE,
  GET_STATUS,
  GET_ALL_RECLAMATIONS_USER,
  LOGOUT,
  FAIL,
  GET_INFORMATION_ID_RECLAMATION,
  LOADING_SUCCESS,
  POST_NEW_COMMENT,
  GET_ALL_RECLAMATION,
} from 'shared/constants/ActionTypes';

const initState = {
  reclamationInfo: [],
  listReclamation: [],
  ListReclamationFromPdv: [],
  owner: localStorage.getItem('id'),
  listComments: null,
  errors: null,
  errorss: null,
  successModal: false,
  details: [],
  services: JSON.parse(localStorage.getItem('services')) || null,
  periorites: JSON.parse(localStorage.getItem('periorites')) || null,
  states: JSON.parse(localStorage.getItem('status')) || null,
  isLoading: false,
  successPostReclamation: false,
  failesPostReclamation: false,
};

const reclamationReducer = (state = initState, {type, payload} = {}) => {
  switch (type) {
    case POST_RECLAMATION_FAIL:
      return {
        ...state,
        errors: payload,
        successModal: false,
        successPostReclamation: false,
        failesPostReclamation: true,
        errorss: payload,
      };

    case POST_RECLAMATION_SUCCESS:
      return {
        ...state,
        reclamationInfo: [
          ...state.reclamationInfo,
          payload.data.newReclamation,
        ],
        errors: null,
        errorss: null,
        successModal: true,
        isLoading: false,
        successPostReclamation: true,
        failesPostReclamation: false,
      };

    case GET_SERVICES:
      localStorage.setItem('services', JSON.stringify(payload.data));
      return {
        ...state,
        services: payload.data,
        successModal: false,
        isLoading: false,
      };

    case GET_PERIORITE:
      localStorage.setItem('periorites', JSON.stringify(payload.data));
      return {
        ...state,
        periorites: payload.data,
        successModal: false,
        isLoading: false,
      };

    case GET_STATUS:
      localStorage.setItem('status', JSON.stringify(payload.data));
      return {
        ...state,
        States: payload.data,
        successModal: false,
        isLoading: false,
      };

    case GET_ALL_RECLAMATIONS_USER:
      return {
        ...state,
        listReclamation: payload.data,
        successModal: false,
        reclamationInfo: payload.data.reclamations,
        isLoading: false,
      };

    case GET_INFORMATION_ID_RECLAMATION:
      return {
        ...state,
        details: payload.data,
        isLoading: false,
      };

    case FAIL:
      return {...state, successModal: false, successPostReclamation: false};

    case LOADING_SUCCESS:
      return {...state, isLoading: true};
    case GET_ALL_RECLAMATION:
      return {
        ...state,
        ListReclamationFromPdv: payload.data.items,
        totalPagesTop: payload.data.totalPages,
      };

    case POST_NEW_COMMENT:
      return {...state, isLoading: false};

    default:
      return state;
  }
};

export default reclamationReducer;
