import React, {useEffect, useState} from 'react';
import Card from '@material-ui/core/Card';
import Box from '@material-ui/core/Box';
import {makeStyles, FormControl, Checkbox} from '@material-ui/core';
import ConfirmTopnetPayment from '../confirmTopnetPayment';
import {getUserBalance, invoiceTopnetPayment} from 'redux/actions';
import {onGetAllSerSupp} from 'redux/actions/Configuration';

import {Fonts} from 'shared/constants/AppEnums';
import {useDispatch, useSelector} from 'react-redux';
import IntlMessages from '@crema/utility/IntlMessages';
import Button from '@material-ui/core/Button';
import clsx from 'clsx';
import {InfoView} from '@crema';
import {CodeOrganisme, formatDate} from 'shared/constants/helpers';
import {fontWeight} from '@mui/system';
import ConfirmPayment from './confirm';
import dataAxios from '@crema/services/data/axiosData';
import {fetchError} from '../../../../../../redux/actions/Common';
import {FETCH_SUCCESS, SHOW_MESSAGE} from 'shared/constants/ActionTypes';

const useStyles = makeStyles((theme) => ({
  logo: {
    height: 24,
  },
  hover: {
    padding: '10px',
    '&:hover': {
      // color: theme.palette.secondary.main,
      backgroundColor: '#5555551a',
    },
    [theme.breakpoints.down('sm')]: {
      padding: 2,
    },
  },
  buttonText: {
    [theme.breakpoints.down('md')]: {
      width: '150px',
      marginBottom: theme.spacing(5),
    },
    [theme.breakpoints.up('sm')]: {
      width: '350px',
    },
  },
  dialogBox: {
    position: 'relative',
    '& .MuiDialog-paperWidthSm': {
      maxWidth: 950,
      width: '100%',
    },
    '& .MuiTypography-h6': {
      fontWeight: Fonts.LIGHT,
    },
  },
  card: {
    maxWidth: 990,
    width: '100%',
    textAlign: 'center',
    paddingLeft: 20,
    paddingRight: 20,
    overflow: 'hidden',

    boxShadow:
      '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)',
    [theme.breakpoints.up('lg')]: {
      padding: '30px 60px 20px 60px',
    },
    [theme.breakpoints.up('xl')]: {
      padding: '48px 40px',
    },
    [theme.breakpoints.down('sm')]: {
      padding: 10,
    },
  },
  form: {
    textAlign: 'left',
  },
  textField: {
    width: '100%',
  },
  button: {
    width: '100%',
    height: 44,
  },
  formControl: {
    width: '100%',
  },
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    flexDirection: 'column',
    backgroundColor: theme.palette.background.paper,
  },

  icon: {
    color: 'rgba(255, 255, 255, 0.54)',
  },
  detailsDocs: {
    padding: 20,
    [theme.breakpoints.down('md')]: {
      padding: 10,
    },
  },
  alignitems: {
    display: 'flex',
    flexDirection: 'row',
    //  marginBottom: '20px',
    padding: 18,
    [theme.breakpoints.down('md')]: {
      padding: 10,
    },
    [theme.breakpoints.down('sm')]: {
      padding: 3,
    },
  },
  title: {
    color: '#659fc2',
    marginLeft: 10,
    marginRight: 4,
    fontWeight: Fonts.BOLD,
    fontSize: 'clamp(16px, 1vw, 17px)',
    [theme.breakpoints.down('md')]: {
      fontSize: 'clamp(16px, 1vw, 17px)',
    },
  },
  totalTitle: {
    color: '#000000',
    marginLeft: 10,
    marginRight: 4,
    fontWeight: 900,
    fontSize: 'clamp(16px, 1vw, 17px)',
    [theme.breakpoints.down('md')]: {
      fontSize: 'clamp(16px, 1vw, 17px)',
    },
  },
  value: {
    marginLeft: 4,
    marginRight: 10,
    fontWeight: Fonts.BOLD,
    fontSize: 'clamp(16px, 1vw, 17px)',
    [theme.breakpoints.down('md')]: {
      fontSize: 'clamp(16px, 1vw, 17px)',
    },
  },
  totalValue: {
    letterSpacing: '2px',
    marginLeft: 4,
    marginRight: 10,
    fontWeight: 900,
    color: '#009688',
    fontSize: 'clamp(16px, 1vw, 17px)',
    [theme.breakpoints.down('md')]: {
      fontSize: 'clamp(16px, 1vw, 18px)',
    },
  },
  mainBox: {
    marginTop: 13,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'space-around',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
    },
  },
  totalAmountBox: {
    borderColor: '#8a8a8a61',
    borderRadius: '5px',
    borderWidth: '1px',
    border: 'solid',

    marginTop: '10%',
    justifyContent: 'space-evenly',
    flexDirection: 'row',
    display: 'flex',
    padding: '20px',
  },
}));

export const useInput = (initialValue) => {
  const [value, setValue] = useState(initialValue);
  return {
    value,
    setValue,
    bind: {
      value,
      onChange: (event) => {
        setValue(event.target.value);
      },
    },
  };
};
const Bills = ({
  organisme,
  selected,
  consultation,
  setActivateDialogOpen,
  history,
  phone,
  img,
  setLoading,
  refAbonnement,
}) => {
  const [load, setLoad] = useState(false);

  const classes = useStyles();
  const dispatch = useDispatch();
  const servSuppObj = useSelector(({voucher}) => voucher.servSuppObj);
  const org = CodeOrganisme(organisme);

  const [checked, setChecked] = useState([]);
  const [listInvoices, setListInvoices] = useState([]);

  const [total, setTotal] = useState(0);
  const [commission, setCommission] = useState(0);
  const [totalsansComission, setTotalsansComission] = useState(0);
  const [isConfirmDialogOpen, setConfirmDialogOpen] = useState(false);

  const getFormattedPrice = (price) => `${Number.parseFloat(price).toFixed(3)}`;

  const onChangeChecked = (event, id) => {
    // console.log('checked before', checked);
    // console.log('commision before', commission);
    // console.log('total sans commision before', totalsansComission);
    // console.log('list invoices before', listInvoices);

    console.log('event.target.checked', event.target.checked);
    if (event.target.checked) {
      setChecked(checked.concat(id));
      console.log('checked inside if ', checked);
      setListInvoices(
        listInvoices.concat({
          ref: selected.find((bill) => bill.id === id).invoiceRef,
          amount: selected.find((bill) => bill.id === id).invoiceAmount / 1000,
        }),
      );
      // setCommission(
      //   selected.find((bill) => bill.id === id).invoiceAmount / 1000 < 300
      //     ? commission + 1
      //     : commission + 2,
      // );
      setTotalsansComission(
        totalsansComission +
          selected.find((bill) => bill.id === id).invoiceAmount / 1000,
      );
    } else {
      setChecked(checked.filter((billId) => billId !== id));
      setListInvoices(
        listInvoices.filter(
          (billId) =>
            billId.ref !== selected.find((bill) => bill.id === id).invoiceRef,
        ),
      );
      setTotalsansComission(
        totalsansComission -
          selected.find((bill) => bill.id === id).invoiceAmount / 1000,
      );
      // setCommission(
      //   selected.find((bill) => bill.id === id).invoiceAmount / 1000 < 300
      //     ? commission - 1
      //     : commission - 2,
      // );
    }
    // setCommission(totalsansComission < 300 ? 1 : 2);
    // console.log('checked after', checked);
    // console.log('commision after', commission);
    // console.log('total sans commision after', totalsansComission);
    // console.log('list invoices after', listInvoices);
  };
  const organisms = useSelector(({invoicePayment}) => invoicePayment.organisms);

  useEffect(() => {
    dispatch(onGetAllSerSupp());
    // const totalPrice = checked.reduce((sum, currentState, index) => {
    //   // return sum;
    //   console.log('sum', sum);
    //   console.log('setListInvoices', listInvoices);

    //   // setListInvoices((listInvoices) => [
    //   //   ...listInvoices,
    //   //   {
    //   //     ref: selected.find((bill) => bill.id === currentState).invoiceRef,
    //   //     amount:
    //   //       selected.find((bill) => bill.id === currentState).invoiceAmount /
    //   //       1000,
    //   //   },
    //   // ]);
    //   // console.log('setListInvoices', listInvoices);
    //   // setTotalsansComission(
    //   //   totalsansComission +
    //   //     selected.find((bill) => bill.id === currentState).invoiceAmount /
    //   //       1000,
    //   // );

    //   return (
    //     sum +
    //     selected.find((bill) => bill.id === currentState).invoiceAmount / 1000
    //   );
    // }, 0);
    // console.log('totalPrice', totalPrice);

    // setTotal(totalPrice);
    setCommission(totalsansComission < 300 ? 1 : 2);
    console.log('commission', commission);
  }, [checked, listInvoices, totalsansComission, dispatch]);

  function onConfirm(data) {
    setConfirmDialogOpen(true);
  }
  const onHide = () => {
    setActivateDialogOpen(false);
  };

  const onClickSendPayment = (e) => {
    e.preventDefault();

    console.log('organisms', organisms);
    setLoading(true);
    const organismeId = organisms.find(
      // console.log(organisme.code)
      (or) => or.code === organisme,
    )?.id;
    console.log('invoice', listInvoices);
    console.log('organismeId : ', organismeId);
    dataAxios
      .post('/topup/invoice-payment', {
        phone: phone,
        organizationId: organismeId,
        amount: totalsansComission,
        adminStatus: 0,
        userStatus: 1,
        commission: commission,
        message: 'payment invoice',
        typeServiceId: servSuppObj['PFC'],
        codeClient: refAbonnement,
        invoices: listInvoices,
        clientPhone: phone,
        type: undefined,
      })
      .then((res) => {
        setLoading(false);
        if (res.status === 200) {
          if (res.data.message === 'the Wallet is already updated!') {
            dispatch({type: FETCH_SUCCESS});
            dispatch({
              type: SHOW_MESSAGE,
              payload: 'Vos factures a été payée avec succès',
            });
            setTimeout(() => {
              dispatch(getUserBalance());
            }, 1500);
          } else if (res.data.code === 2003) {
            dispatch(fetchError('Votre facture a été déjà payée'));
          } else if (res?.data?.data?.code === 2000) {
            dispatch(
              fetchError(
                'Votre solde est insuffisant! Veuillez alimenter votre compte',
              ),
            );
          } else {
            dispatch(
              fetchError(
                'Vous ne pouvez pas effectuer le paiement pour le moment',
              ),
            );
          }
        } else {
          dispatch(
            fetchError("Une erreur s'est produite. Veuillez réessayer!"),
          );
        }
        setConfirmDialogOpen(false);
        onHide();
      })
      .catch((error) => {
        onHide();
        console.log('ssss ' + error);
        dispatch(fetchError('Verifiez vos informations svp !'));
      });

    console.log('paymen');
  };
  return (
    <Box
      pb={0}
      py={{xl: 2}}
      mt={10}
      display='flex'
      flex={1}
      flexDirection='column'
      alignItems='center'
      justifyContent='center'>
      <Card className={classes.card}>
        <Box mb={{xs: 5, xl: 8}} display='flex' justifyContent='space-around'>
          <Button color='info' className={classes.imgCard}>
            <img alt='topnet' className='img-col' name='ooredoo' src={img} />
          </Button>
        </Box>
        <InfoView />
        <Box display='flex' alignItems='flex-start' flexDirection='column'>
          <Box mt={4} fontWeight={Fonts.BOLD} fontSize={18}>
            Téléphone: {phone}
          </Box>
          <Box mt={8} fontSize={16}>
            Choisissez les factures à payer:
          </Box>
        </Box>
        <FormControl component='fieldset'>
          <div style={{overflow: 'scroll', height: '350px'}}>
            {selected.map((item, index) => (
              <Box p={5} m={2} className={clsx('pointer', classes.hover)}>
                <Card>
                  {' '}
                  <Box display='flex'>
                    <Checkbox
                      checked={checked.includes(item.id)}
                      onChange={(event) => onChangeChecked(event, item.id)}
                      color='primary'
                      className={classes.checkboxRoot}
                    />

                    <Box display='flex' className={classes.mainBox}>
                      <Box className={classes.alignitems}>
                        <Box className={classes.title}>Date édition:</Box>
                        <Box className={classes.value}>
                          {item?.invoiceDate
                            ? formatDate(item?.invoiceDate)
                            : '--'}
                        </Box>
                      </Box>
                      <Box className={classes.alignitems}>
                        <>
                          {' '}
                          <Box className={classes.title}>Num facture:</Box>
                          <Box className={classes.value}>
                            {' '}
                            {item?.invoiceRef ? item?.invoiceRef : '--'}
                          </Box>
                        </>
                      </Box>

                      {/* <Box className={classes.alignitems}>
                      <Box className={classes.title}>Prix:</Box>
                      <Box className={classes.value}>
                        {' '}
                        {item?.amount_bill ? item?.amount_bill : '--'}
                      </Box>
                    </Box> */}
                      <Box className={classes.alignitems}>
                        <Box className={classes.title}>Montant:</Box>
                        <Box className={classes.value}>
                          {item?.invoiceAmount
                            ? getFormattedPrice(item?.invoiceAmount / 1000)
                            : '--'}{' '}
                          TND
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Card>
              </Box>
            ))}
          </div>

          <Box className={classes.totalAmountBox}>
            <Box className={classes.totalTitle}>Montant total à payer:</Box>
            <Box className={classes.totalValue}>
              {getFormattedPrice(totalsansComission + commission)} TND{' '}
            </Box>
          </Box>
          <Box
            mb={6}
            display='flex'
            flexDirection={{xs: 'column', sm: 'row'}}
            alignItems={{sm: 'center'}}
            justifyContent={{sm: 'space-between'}}></Box>
          <Box
            //bgcolor={grey[100]}
            //px={{xs: 6, sm: 10, xl: 15}}
            py={{xs: 3, xl: 4}}
            display='flex'
            justifyContent='space-between'
            alignItems='center'>
            <Button
              onClick={() => onHide()}
              variant='contained'
              type='reset'
              className={clsx(classes.btnRoot)}>
              <IntlMessages id='common.cancel' />
            </Button>
            <Button
              disabled={checked.length <= 0}
              onClick={onConfirm}
              variant='contained'
              color='primary'
              type='submit'
              className={clsx(classes.btnRoot)}>
              <IntlMessages id='common.send' />
            </Button>
          </Box>
          {isConfirmDialogOpen ? (
            <ConfirmPayment
              open={isConfirmDialogOpen}
              onDeny={!isConfirmDialogOpen}
              onConfirm={onClickSendPayment}
              setActivateDialogOpen={setConfirmDialogOpen}
              selected={selected}
              total={total}
              totalsansComission={totalsansComission}
              commission={commission}
              loading={load}
              title={'Veuillez confirmer votre paiement'}
              dialogTitle={<IntlMessages id='dialog.topup.payment' />}
            />
          ) : null}
        </FormControl>
      </Card>
    </Box>
    // </Dialog>
  );
};

export default Bills;
