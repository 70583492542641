import {combineReducers} from 'redux';
import {connectRouter} from 'connected-react-router';
import Settings from './Setting';
import Common from './Common';
import Auth from './Auth';
import Configuration from './Configuration';
import Voucher from './Voucher';
import Poste from './Configuration';
import PosteComp from './poste';
import AlimentationCompte from './AlimentationCompte';
import Mintroute from './Mintroute';
import Blogs from './Blogs';
import InvoicePayment from './InvoicePayment';
import Hotels from './Hotels';
import Reclamation from './Reclamation';
import History from './History';

const reducers = (history) =>
  combineReducers({
    router: connectRouter(history),
    settings: Settings,
    auth: Auth,
    common: Common,
    configuration: Configuration,
    voucher: Voucher,
    poste: Poste,
    getPoste: PosteComp,
    alimentationCompte: AlimentationCompte,
    mintroute: Mintroute,
    invoicePayment: InvoicePayment,
    hotels: Hotels,
    Reclamation: Reclamation,
    History: History,
  });
export default reducers;
