import {
  CLICKTOPAY_ORDER_ID,
  GET_SOURCE_LIST,
  SET_INITIAL_PATH,
  TOGGLE_NAV_COLLAPSED,
} from '../../shared/constants/ActionTypes';

const initialSettings = {
  navCollapsed: false,
  initialPath: '/',
  orderId: '',
  source: [],
};

const settingsReducer = (state = initialSettings, action) => {
  switch (action.type) {
    case '@@router/LOCATION_CHANGE':
      return {
        ...state,
        navCollapsed: false,
      };

    case TOGGLE_NAV_COLLAPSED:
      return {
        ...state,
        navCollapsed: !state.navCollapsed,
      };

    case SET_INITIAL_PATH:
      return {
        ...state,
        initialPath: action.payload,
      };
    case CLICKTOPAY_ORDER_ID: {
      return {
        ...state,
        orderId: action.payload,
      };
    }
    case GET_SOURCE_LIST: {
      return {
        ...state,
        source: action.payload.data,
      };
    }
    default:
      return state;
  }
};

export default settingsReducer;
