import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import {grey} from '@material-ui/core/colors';
import {Fonts} from 'shared/constants/AppEnums';
import LoaderInfo from '@crema/core/Loader/LoaderInfo';

import {
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@material-ui/core';
import IntlMessages from '@crema/utility/IntlMessages';

const ConfirmTopnetPayment = ({
  selected,
  // selectedAmount,
  open,
  onConfirm,
  setActivateDialogOpen,
  title,
  total,
  loading,
}) => {
  const useStyle = makeStyles({
    btn: {
      marginLeft: 8,
      fontWeight: Fonts.MEDIUM,
    },
    contentText: {
      fontSize: 18,
      fontFamily: 'bold',
      color: grey[600],
    },
    title: {fontFamily: 'bold', color: 'black', fontSize: 18},
    value: {fontFamily: 'bold', padding: 10, fontSize: 20, color: '#003255'},
    buttonCancel: {
      '&:hover': {
        backgroundColor: '#c80c0c;',
      },
      width: '100%',
      height: 44,
      color: 'white',
      backgroundColor: 'red',
    },
    button: {
      width: '100%',
      height: 44,
      backgroundColor: '#003255',
      color: 'white',
    },
    root: {
      '& .MuiFormControl-root': {
        width: '100%',
      },
      '& .MuiInputBase-root': {
        height: '55px',
        borderRadius: '4px',
        width: '100%',
        backgroundColor: '#ECECEC',
      },
      '& .MuiSelect-root': {
        backgroundColor: '#ECECEC',
        borderRadius: '8px',
      },

      '& .MuiFormLabel-root': {
        paddingLeft: '0px',
        paddingRight: '4px',
        font: 'italic normal 300 21px Segoe UI;',
        fontWeight: 400,
        lineHeight: 1,
        backgroundColor: '#ECECEC',
      },
      '& .MuiTypography-body1': {
        fontSize: '18px',
        marginRight: '20px',
      },
      '& .MuiRadio-colorSecondary.Mui-checked': {
        color: '#003250',
      },
    },
  });
  const classes = useStyle();
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState('sm');
  const [date, setDate] = React.useState('1');

  const handleChangeRadio = (event) => {
    setDate(event.target.value);
  };

  const onHide = () => {
    setActivateDialogOpen(false);
  };

  return (
    <>
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        aria-labelledby='simple-modal-title'
        aria-describedby='simple-modal-description'
        className={classes.dialogBox}
        open={open}
        onClose={() => onHide()}>
        {loading && <LoaderInfo />}

        <Box px={{xs: 5, md: 7}} pt={{xs: 4, md: 6}} pb={{xs: 2, md: 4}}>
          <Box mb={{xs: 6, md: 8, xl: 18}}>
            <Box display='flex' justifyContent='center'>
              <img
                className={classes.image}
                src={'/assets/images/Raccourci/Paiement de Facture et IMF.svg'}
                alt='success'
                width='80px'
              />
            </Box>
          </Box>
          <DialogTitle id='customized-dialog-title'>
            {selected?.organisme === 'ORANGE' ? (
              <FormControl component='fieldset'>
                {total.map((i, index) => (
                  <RadioGroup
                    aria-label='date'
                    defaultValue='1'
                    onChange={handleChangeRadio}
                    style={{
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      fontSize: '20px',
                    }}
                    //flexDirection='column'
                    name='date'>
                    <FormControlLabel
                      value={index}
                      className={classes.root}
                      control={<Radio />}
                      label={i}
                    />
                  </RadioGroup>
                ))}
              </FormControl>
            ) : total ? (
              total[0]
            ) : null}
          </DialogTitle>
          Veuillez cliquer sur oui pour confirmer
          <Box>
            <DialogContent
              display='flex'
              justifyContent='center'
              className={classes.contentText}
              id='alert-dialog-description'>
              <Box
                style={{
                  alignItems: 'center',
                  flexDirection: 'row',
                  display: 'flex',
                }}>
                {/* <Box className={classes.title}>Prénom:</Box>
                <Box className={classes.value}>{selected?.name}</Box>
                <Box className={classes.title}>Nom:</Box>
                <Box className={classes.value}>{selected?.last_name}</Box> */}
                <>
                  <>
                    <Box
                      style={{
                        fontFamily: 'bold',
                        color: 'black',
                        fontSize: 18,
                      }}>
                      Prénom:
                    </Box>
                    <Box
                      style={{
                        fontFamily: 'bold',
                        padding: 10,
                        fontSize: 20,
                        color: '#003255',
                      }}>
                      {selected?.name}
                    </Box>
                  </>
                  <>
                    <Box
                      style={{
                        fontFamily: 'bold',
                        color: 'black',
                        fontSize: 18,
                      }}>
                      Nom:
                    </Box>
                    <Box
                      style={{
                        fontFamily: 'bold',
                        padding: 10,
                        fontSize: 20,
                        color: '#003255',
                      }}>
                      {selected?.last_name}
                    </Box>
                  </>
                  <></>
                </>
              </Box>
              <Box
                style={{
                  alignItems: 'center',
                  flexDirection: 'row',
                  display: 'flex',
                }}>
                <Box className={classes.title}>Montant total:</Box>
                <Box className={classes.value}>
                  {total}
                  DT
                </Box>
              </Box>

              <DialogActions>
                <Button
                  className={classes.buttonCancel}
                  variant='contained'
                  color='danger'
                  type='submit'
                  onClick={() => onHide()}>
                  <IntlMessages id='common.no' />
                </Button>
                <Button
                  className={classes.button}
                  onClick={onConfirm}
                  variant='contained'
                  color='primary'
                  type='submit'>
                  <IntlMessages id='common.yes' />
                </Button>
              </DialogActions>
            </DialogContent>
          </Box>
        </Box>
      </Dialog>
    </>
  );
};

export default ConfirmTopnetPayment;

ConfirmTopnetPayment.propTypes = {
  dialogTitle: PropTypes.bool.isRequired,
  open: PropTypes.bool.isRequired,
  onDeny: PropTypes.func.isRequired,
  title: PropTypes.node.isRequired,
  onConfirm: PropTypes.func.isRequired,
  setActivateDialogOpen: PropTypes.func.isRequired,
};
