import React from 'react';
import {authRole} from 'shared/constants/AppConst';
import ApiConsult from './Pages/paymentServices/PaymentForms/Api Payment/ApiConsult';

export const teamPagesConfig = [
  {
    auth: authRole.user,
    routes: [
      {
        exact: true,
        path: '/Menu-paiement/topnet',
        component: React.lazy(() =>
          import('./Pages/paymentServices/PaymentForms/topnetForm'),
        ),
      },
    ],
  },
  {
    auth: authRole.user,
    routes: [
      {
        exact: true,
        path: '/Menu-paiement/steg',
        render: (props) => (
          <React.Suspense fallback={<div>Loading...</div>}>
            <ApiConsult
              {...props}
              organisme='STEG'
              img='/assets/images/organismes/steg.jpg'
              imgRefAbonnement='/assets/images/organismes/stegRefAbon.jpg'
            />
          </React.Suspense>
        ),
      },
    ],
  },
  {
    auth: authRole.user,
    routes: [
      {
        exact: true,
        path: '/paiement-historique',
        component: React.lazy(() => import('./Pages/PaymentHistory')),
      },
      {
        exact: true,
        path: '/paiement-Topnet',
        component: React.lazy(() => import('./Pages/PaymentHistoryTopnet')),
      },
    ],
  },
  {
    auth: authRole.user,
    routes: [
      {
        exact: true,
        path: '/Menu-paiement',
        component: React.lazy(() => import('./Pages/paymentServices/Payments')),
      },
    ],
  },
  {
    auth: authRole.user,
    routes: [
      {
        exact: true,
        path: '/Menu-paiement/amende-radar',
        component: React.lazy(() =>
          import('./Pages/paymentServices/PaymentForms/AmendeRadar'),
        ),
      },
      {
        exact: true,
        path: '/Menu-paiement/tunisie-autoroute',
        component: React.lazy(() =>
          import('./Pages/paymentServices/PaymentForms/Autoroute'),
        ),
      },
      {
        exact: true,
        path: '/Menu-paiement/sonede',
        render: (props) => (
          <React.Suspense fallback={<div>Loading...</div>}>
            <ApiConsult
              {...props}
              organisme='SONEDE'
              img='/assets/images/organismes/sonede.jpg'
              imgRefAbonnement='/assets/images/organismes/sonede code-01.jpg'
            />
          </React.Suspense>
        ),
      },
    ],
  },
  // {
  //   auth: authRole.user,
  //   routes: [
  //     {
  //       exact: true,
  //       path: '/Menu-paiement/:id',
  //       component: React.lazy(() =>
  //         import('./Pages/paymentServices/PaymentForms/formPayment'),
  //       ),
  //     },
  //   ],
  // },
  {
    auth: authRole.user,
    routes: [
      {
        exact: true,
        path: '/Menu-paiement/telecom',
        component: React.lazy(() =>
          import('./Pages/paymentServices/PaymentForms/telecom'),
        ),
      },
    ],
  },
];
