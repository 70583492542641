import dataAxios from '@crema/services/data/axiosData';
import {appIntl} from '@crema/utility/Utils';
import {
  SHOW_MESSAGE,
  FETCH_START,
  FETCH_SUCCESS,
  CONSULTATION_INVOICE,
  GET_ALL_POSTE_PAYMENT_INVOICES,
  GET_ALL_POSTE_PAYMENT_ORGANISMS,
  GET_ALL_BILLS,
  GET_NB_PRINTED,
  GET_ALL_BILLS_STEG,
} from 'shared/constants/ActionTypes';
import {fetchError} from './Common';
import {getUserBalance} from './JWTAuth';
export const invoicePostePayment = (service, setLoading) => {
  const {messages} = appIntl();

  return (dispatch) => {
    const body = service;
    console.log('body', body);
    dataAxios
      .post('/topup/bill-payment', body)
      .then((res) => {
        setLoading(false);
        if (res.status === 200) {
          if (res.data.message === 'the Wallet is already updated!') {
            dispatch({type: FETCH_SUCCESS});
            dispatch({
              type: SHOW_MESSAGE,
              payload: 'Votre facture a été payée avec succès',
            });
            setTimeout(() => {
              dispatch(getUserBalance());
            }, 1500);
          } else if (res.data.code === 2003) {
            dispatch(fetchError('Votre facture a été déjà payée'));
          } else if (res?.data?.data?.code === 2000) {
            dispatch(
              fetchError(
                'Votre solde est insuffisant! Veuillez alimenter votre compte',
              ),
            );
          } else {
            dispatch(
              fetchError(
                'Vous ne pouvez pas effectuer le paiement pour le moment',
              ),
            );
          }
        } else {
          dispatch(fetchError(messages['message.somethingWentWrong']));
        }
      })
      .catch((error) => {
        setLoading(false);

        dispatch(
          fetchError(
            'Vous ne pouvez pas effectuer cette action pour le moment',
          ),
        );
      });
  };
};
export const consultationInvoice = (service, handleOpen, setLoading) => {
  const {messages} = appIntl();

  return (dispatch) => {
    const body = service;
    // dispatch({type: FETCH_START});
    dataAxios
      .post('/topup/consultation-invoice', body)
      .then((res) => {
        setLoading(false);
        if (res) {
          if (res.status === 201) {
            if (res.data.data.code === 2202) {
              dispatch({type: FETCH_SUCCESS});
              dispatch({
                type: CONSULTATION_INVOICE,
                payload: res.data.data.message,
              });
              handleOpen({
                ...service,
                message: res.data.data.message,
                clientPhone: body.clientPhone,
                amount: res.data.data.amount,
              });
            }
            if (res.data.data.code === 2204) {
              dispatch(fetchError(res.data.data.message));

              //handleOpen(service);
            }
            if (res.data.data.code === 2001) {
              dispatch(
                fetchError('Service non disponible. Réessayer plus tard'),
              );
            }
            if (res.data.data.code === 2005) {
              dispatch(fetchError(messages['message.somethingWentWrong']));
            }
            //handleOpen(service);
          }
        } else {
          dispatch(fetchError('Service non disponible pour le moment'));
        }
      })
      .catch((error) => {
        setLoading(false);

        dispatch(
          fetchError(
            'Vous ne pouvez pas effectuer cette action pour le moment',
          ),
        );
      });
  };
};
export const consultationTopnetPayment = (params, handleOpen, setLoading) => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .get('/topnet/bill', {params: params})
      .then((data) => {
        setLoading(false);
        if (data.status === 200) {
          //console.log('Topnet  dataasa', data.data.data);

          if (data.data.code === 2203) {
            dispatch({type: FETCH_SUCCESS});
            dispatch({
              type: SHOW_MESSAGE,
              payload:
                'Cette référence ne possède aucune facture à payer pour le moment ',
            });
          }
          if (data.data.code === 2202) {
            //console.log('Topnet  dataasa', data.data.data);
            dispatch({type: FETCH_SUCCESS});
            dispatch({
              type: GET_ALL_BILLS,
              payload: data.data.data,
            });
            handleOpen(data.data.data);
          }
        } else {
          dispatch(fetchError(messages['message.somethingWentWrong']));
        }
      })
      .catch((error) => {
        setLoading(false);

        dispatch(
          fetchError(
            'Vous ne pouvez pas effectuer cette action pour le moment',
          ),
        );
      });
  };
};

export const consultationSTEGPayment = (body, handleOpen, setLoading) => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .post('/topup/custominvoice', body)
      .then((data) => {
        setLoading(false);
        if (data.status === 200) {
          if (data.data.code === 2202) {
            //console.log('Topnet  dataasa', data.data.data);
            dispatch({type: FETCH_SUCCESS});
            dispatch({
              type: GET_ALL_BILLS_STEG,
              payload: data.data.data,
            });
            handleOpen(data.data.data);
            console.log('mouadh', data.data.data);
          }
        } else {
          dispatch(fetchError(messages['message.somethingWentWrong']));
        }
      })
      .catch((error) => {
        setLoading(false);
        dispatch(
          fetchError(
            'Vous ne pouvez pas effectuer cette action pour le moment',
          ),
        );
      });
  };
};
export const invoiceTopnetPayment = (service, setLoading, onHide, history) => {
  const {messages} = appIntl();
  return (dispatch) => {
    const body = service;
    console.log('ssssss11111111', service);
    dispatch({type: FETCH_START});
    dataAxios
      .post('/topnet/payment', body)
      .then((data) => {
        dispatch({type: FETCH_SUCCESS});
        setLoading(false);

        if (data.status === 200) {
          //console.log('topneet error', data.data);
          onHide();
          if (data.data.status === 'error') {
            dispatch(fetchError(messages['message.somethingWentWrong']));
          } else {
            dispatch({type: FETCH_SUCCESS});
            dispatch({
              type: SHOW_MESSAGE,
              payload: 'Votre facture a été payée avec succès',
            });
            if (data.data.data.code === 2000) {
              dispatch(fetchError(messages['wallet.insufficient']));
            }
          }
          setTimeout(() => {
            history.push('/Menu-paiement/topnet');
          }, 2000);
        } else {
          dispatch(fetchError(messages['message.somethingWentWrong']));
          setTimeout(() => {
            history.push('/Menu-paiement/topnet');
          }, 2000);
        }
      })
      .catch((error) => {
        setLoading(false);

        dispatch(fetchError("Un erreur s'est produit veuillez réessayer"));
      });
  };
};
export const onGetCsvFile = (exportUrl, port, params) => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .get(exportUrl, {params: params})
      .then((res) => {
        if (res.status === 200) {
          //console.log(res);
          dispatch({type: FETCH_SUCCESS});
          if (res.data.code === 2202) {
            dispatch(
              onUploadFiles({
                url: res.data.data.path.slice(1),
                port: port,
              }),
            );
          } else {
            dispatch(
              fetchError(
                "Vous ne pouvez pas accéder à l'historique pour le moment",
              ),
            );
          }
        } else {
          dispatch(fetchError(messages['message.somethingWentWrong']));
        }
      })
      .catch((error) => {
        dispatch(
          fetchError(
            "Vous ne pouvez pas accéder à l'historique pour le moment",
          ),
        );
      });
  };
};

export const onGetXlmFile = (exportUrl, port, params) => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .get(exportUrl, {params: params})
      .then((res) => {
        if (res.status === 200) {
          //console.log(res);
          dispatch({type: FETCH_SUCCESS});
          if (res.data.code === 2202) {
            dispatch(
              onUploadFiles({
                url: res.data.data.path.slice(1),
                port: port,
              }),
            );
          } else {
            dispatch(
              fetchError(
                "Vous ne pouvez pas accéder à l'historique pour le moment",
              ),
            );
          }
        } else {
          dispatch(fetchError(messages['message.somethingWentWrong']));
        }
      })
      .catch((error) => {
        dispatch(
          fetchError(
            "Vous ne pouvez pas accéder à l'historique pour le moment",
          ),
        );
      });
  };
};

export const onUploadFiles = (params) => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .post('/upload', params)
      .then((res) => {
        if (res.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          if (res.data.path) {
            window.open(res.data.path, '_blank');
          } else {
            dispatch(
              fetchError('Vous ne pouvez générer le document pour le moment'),
            );
          }
        } else {
          dispatch(fetchError(messages['message.somethingWentWrong']));
        }
      })
      .catch((error) => {
        dispatch(
          fetchError('Vous ne pouvez générer le document pour le moment'),
        );
      });
  };
};
export const getAllPaymentInvoices = (params) => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .get('/topup/organization', {params: params})
      .then((res) => {
        if (res.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: GET_ALL_POSTE_PAYMENT_INVOICES,
            payload: res.data.data,
          });
        } else {
          dispatch(fetchError(messages['message.somethingWentWrong']));
        }
      })
      .catch((error) => {
        dispatch(fetchError());
      });
  };
};
export const getAllPaymentOrganisms = (params) => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .get('/topup/organization', {params: params})
      .then((res) => {
        if (res.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: GET_ALL_POSTE_PAYMENT_ORGANISMS,
            payload: res.data.data,
          });
        } else {
          dispatch(fetchError(messages['message.somethingWentWrong']));
        }
      })
      .catch((error) => {
        dispatch(fetchError());
      });
  };
};
/*export const onDownload = (id) => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .put('/voucher/transaction/download/' + id, {from: 2222})
      .then((res) => {
        if (res.status === 200) {
          if (res.data.data.code === 2202) {
            dispatch({type: FETCH_SUCCESS});
            dispatch({
              type: GET_NB_PRINTED,
              payload: res.data.data.data.printed,
            });
          } else {
            dispatch(fetchError(messages['message.somethingWentWrong']));
          }
          // console.log(res.data);
        } else {
          dispatch(fetchError(messages['message.somethingWentWrong']));
        }
      })
      .catch((error) => {
        dispatch(
          fetchError('Vous ne pouvez générer le document pour le moment'),
        );
      });
  };
};*/
// donloadvoucher for newprocess
export const onDownloadVoucher = (id) => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .put('/voucher/transaction/download/' + id, {from: 2222})
      .then((res) => {
        if (res.status === 200) {
          if (res.data.data.code === 2202) {
            dispatch({type: FETCH_SUCCESS});
            dispatch({
              type: GET_NB_PRINTED,
              payload: res.data.data.data.printed,
            });
          } else {
            dispatch(fetchError(messages['message.somethingWentWrong']));
          }
          // console.log(res.data);
        } else {
          dispatch(fetchError(messages['message.somethingWentWrong']));
        }
      })
      .catch((error) => {
        dispatch(
          fetchError('Vous ne pouvez générer le document pour le moment'),
        );
      });
  };
};
