import React from 'react';
import {authRole} from 'shared/constants/AppConst';

export const fournisseurConfig = [
  {
    auth: authRole.fournisseur,
    routes: [
      {
        path: '/Topup-Historique',
        component: React.lazy(() => import('./Pages/HistoryTopup')),
      },
      {
        path: '/Voucher-Historique',
        component: React.lazy(() => import('./Pages/HistoryVoucher')),
      },
      {
        path: '/Stock-Topup',
        component: React.lazy(() => import('./Pages/StockTopup')),
      },
      {
        path: '/Stock-Voucher',
        component: React.lazy(() => import('./Pages/StockVoucher')),
      },
    ],
  },
];
