import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import ReactLoading from 'react-loading';

const Loader = () => {
  return (
    <Box
      height='100%'
      display='flex'
      flex={1}
      alignItems='center'
      justifyContent='center'
      position='absolute'
      top={60}
      left={0}
      zIndex={500}
      right={0}
      bottom={0}>
      <ReactLoading type={'spin'} color='#003250' width='90px' heignt='90px' />
      {/* <CircularProgress /> */}
    </Box>
  );
};

export default Loader;
