import React from 'react';
import {authRole} from 'shared/constants/AppConst';

export const reclamationPageConfig = [
  {
    auth: authRole.user,
    routes: [
      {
        path: '/reclamation',
        component: React.lazy(() => import('./Pages/AddReclamation')),
      },
      {
        path: '/GestionReclamation',
        component: React.lazy(() => import('./Pages/ReclamationGestion')),
      },
    ],
  },
];
