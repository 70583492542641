import React, {useEffect, useState} from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import {Container, FormControl, Modal} from '@material-ui/core';
import {Form, Formik, useField} from 'formik';
import {useDispatch, useSelector} from 'react-redux';
import InfoView from '@crema/core/InfoView';
import {useHistory} from 'react-router-dom';
import Box from '@material-ui/core/Box';
import IntlMessages from '@crema/utility/IntlMessages';
import {useIntl} from 'react-intl';
import clsx from 'clsx';
import useStyles from '../../../../../form.style';
import {ComponentHeader, GridContainer} from '@crema';
import AppAnimate from '@crema/core/AppAnimate';
import {
  fetchErrorLong,
  getAllPaymentOrganisms,
  onGetEnabledServices,
} from 'redux/actions';
import {onGetAllSerSupp} from 'redux/actions/Configuration';
import Bills from './Bill';
import dataAxios from '@crema/services/data/axiosData';
import {fetchError} from '../../../../../../redux/actions/Common';
import {FETCH_START, FETCH_SUCCESS} from 'shared/constants/ActionTypes';

const MyTextField = (props) => {
  const classes = useStyles(props);
  const [field, meta] = useField(props);
  const errorText = meta.error && meta.touched ? meta.error : '';
  return (
    <TextField
      {...props}
      {...field}
      fullWidth
      className={classes.root}
      helperText={errorText}
      error={!!errorText}
    />
  );
};

const ApiConsult = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [values, setValues] = useState({type: 0});

  const {messages} = useIntl();
  const classes = useStyles(props);
  const servSuppObj = useSelector(({voucher}) => voucher.servSuppObj);
  const services = useSelector(({configuration}) => configuration.service);
  const [listBill, setListBill] = useState([]);
  const [isActivateDialogOpen, setActivateDialogOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [phone, setPhone] = useState('');
  const [ref, setRef] = useState('');

  const [billList, setBillList] = useState(null);

  const handleOpen = (data) => {
    setBillList(data);
    setActivateDialogOpen(true);
  };
  useEffect(() => {
    setLoading(true);
    const checkValues = () => {
      if (services.length == 0) {
        dispatch(onGetAllSerSupp());
      }
    };
    checkValues();
    dispatch(onGetEnabledServices());
    dispatch(getAllPaymentOrganisms());
  }, [dispatch]);

  const submitHandler = (e) => {
    setLoading(true);

    if (ref) {
      dispatch({type: FETCH_START});

      dataAxios
        .post('/topup/custominvoice', {
          reference: ref,
          organisme: props.organisme,
          phone: phone,
        })
        .then((data) => {
          setLoading(false);
          if (data.status == 200) {
            if (data.data.code == 2202) {
              setListBill(data.data.data);
              handleOpen(data.data.data);
              console.log('mouadh', data.data.data);
              dispatch({type: FETCH_SUCCESS});
            } else {
              dispatch(fetchError('Aucune facture trouvée'));
            }
          } else {
            dispatch(fetchError('Aucune facture trouvée'));
          }
        })
        .catch((error) => {
          setLoading(false);
          dispatch(fetchError('Verifiez vos informations svp !'));
        });
    } else {
      setLoading(false);
      dispatch(
        fetchError('Vous ne pouvez pas effectuer cette action pour le moment'),
      );
    }
  };
  const handleClose = () => {
    setActivateDialogOpen(false);
  };
  const {invoiceServices} = useSelector(({auth}) => auth);

  useEffect(() => {
    if (!invoiceServices?.PFC) {
      dispatch(fetchErrorLong('Ce service est non disponible pour le moment'));
    }
  }, [invoiceServices]);

  return (
    <>
      <ComponentHeader />
      <AppAnimate
        justifyContent='center'
        animation='transition.slideUpIn'
        delay={200}>
        <Container className={classes.container} maxWidth='md'>
          <GridContainer>
            <InfoView />
            <Box width='100%' flex={1} display='flex' flexDirection='column'>
              <Box
                px={{xs: 6, sm: 10, xl: 15}}
                pt={8}
                flex={1}
                display='flex'
                flexDirection='column'>
                <Formik
                  validateOnChange={true}
                  initialValues={{
                    code: undefined,
                    libelle_contrat: undefined,
                    num_facture: undefined,
                  }}
                  // validationSchema={validationSchema}
                  onSubmit={submitHandler}>
                  {({isSubmitting, setFieldValue, values}) => (
                    <Form
                      className={classes.formRoot}
                      noValidate
                      autoComplete='off'>
                      <Box
                        mb={{xs: 5, xl: 8}}
                        display='flex'
                        justifyContent='space-around'>
                        <Button color='info' className={classes.imgCard}>
                          <img
                            alt='STEG'
                            className='img-col'
                            name='ooredoo'
                            src={props.img}
                          />
                        </Button>
                      </Box>
                      <Box
                        mb={6}
                        display='flex'
                        flexDirection={{xs: 'column', sm: 'row'}}
                        justifyContent={{sm: 'space-between'}}>
                        <Box
                          mb={{xs: 5, xl: 8}}
                          mt={{xl: 5}}
                          style={{width: '80%'}}>
                          <FormControl
                            variant='outlined'
                            className={classes.formControl}>
                            <Box mb={{xs: 5, xl: 8}}>
                              <TextField
                                InputProps={{
                                  inputProps: {min: 7, maxLength: 9},
                                }}
                                style={{width: '100%'}}
                                variant='outlined'
                                id='reference'
                                type='text'
                                placeholder="Numéro d'abonnement"
                                onChange={(e) => setRef(e.target.value)}
                                name='reference'
                                value={ref}
                                className={classes.dateField}
                              />
                            </Box>
                            <Box mb={{xs: 5, xl: 5}}>
                              <TextField
                                style={{
                                  width: '100%',
                                  background: 'transparent',
                                }}
                                variant='outlined'
                                id='phone'
                                type='text'
                                placeholder={messages['common.phone']}
                                onChange={(e) => setPhone(e.target.value)}
                                name='phone'
                                value={phone}
                                className={classes.dateField}
                              />
                            </Box>
                          </FormControl>
                        </Box>
                        {props.imgRefAbonnement ? (
                          <Box>
                            <img
                              alt='STEG'
                              style={{marginLeft: '10px', width: '100%'}}
                              name='ooredoo'
                              src={props.imgRefAbonnement}
                            />
                          </Box>
                        ) : (
                          <></>
                        )}
                      </Box>

                      <Box
                        mb={6}
                        display='flex'
                        flexDirection={{xs: 'column', sm: 'row'}}
                        alignItems={{sm: 'center'}}
                        justifyContent={{sm: 'space-between'}}></Box>
                      <Box
                        py={{xs: 3, xl: 4}}
                        display='flex'
                        justifyContent='space-between'
                        alignItems='center'>
                        <Button
                          variant='contained'
                          type='reset'
                          className={clsx(classes.btnRoot)}>
                          <IntlMessages id='common.cancel' />
                        </Button>
                        <Button
                          variant='contained'
                          color='primary'
                          type='submit'
                          disabled={!invoiceServices?.PFC}
                          className={clsx(classes.btnRoot)}>
                          <IntlMessages id='common.visit' />
                        </Button>
                      </Box>
                    </Form>
                  )}
                </Formik>
              </Box>
            </Box>
          </GridContainer>
          {!loading ? (
            <Modal
              open={isActivateDialogOpen}
              onClose={handleClose}
              aria-labelledby='modal-modal-title'
              aria-describedby='modal-modal-description'
              style={{overflow: 'scroll', height: '100%'}}>
              <Bills
                open={isActivateDialogOpen}
                onDeny={!isActivateDialogOpen}
                onConfirm={() => console.log('Topnet onConfirm')}
                setActivateDialogOpen={setActivateDialogOpen}
                selected={billList}
                consultation={listBill}
                history={history}
                phone={phone}
                setLoading={setLoading}
                organisme={props.organisme}
                img={props.img}
                refAbonnement={ref}
                dialogTitle={<IntlMessages id='dialog.topup' />}
              />
            </Modal>
          ) : (
            <></>
          )}
        </Container>
      </AppAnimate>
    </>
  );
};

export default ApiConsult;
