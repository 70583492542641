import React from 'react';
import {authRole} from 'shared/constants/AppConst';

export const orderPagesConfig = [
  {
    auth: authRole.user,
    routes: [
      {
        path: '/ajouter_commande',
        component: React.lazy(() => import('./commande/RedirectDigidis')),
      },
      /*{
        path: '/historique_commandes',
        component: React.lazy(() => import('./commande/HistoriqueCommand')),
      },
      */
    ],
  },
];
