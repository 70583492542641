export const authRole = {
  admin: ['admin'],
  user: ['ROLE_USER', 'ROLE_POS'],
  visitor: ['ROLE_TOPNET', 'ROLE_USER', 'ROLE_PDV_VIP', 'ROLE_FOURNISSEUR'],
  onlyVisitor: ['ROLE_TOPNET'],
  onlyVisitorVip: ['ROLE_PDV_VIP'],
  vipUser: ['ROLE_USER', 'ROLE_POS', 'ROLE_PDV_VIP'],
  fournisseur: ['ROLE_FOURNISSEUR'],
};

export const defaultUser = {
  displayName: 'John Alex',
  email: 'demo@example.com',
  token: 'access-token',
  role: 'user',
  photoURL: 'https://via.placeholder.com/150',
};
//export const khallasliUrl = 'https://refonte.khallasli.com';
// const role = localStorage.getItem('role');

//export const initialUrl = role === 'user' ? '/home.html' : '/registration-confirm'; // this url will open after login

//export const initialUrl = '/home.html'; // this url will open after login

export const initialUrl = '/dashboard';
// this url will be opened after login
 export const baseUrl = 'https://api.khallasli.com';
//export const baseUrl = 'https://refonte-api.khallasli.com';
//  export const baseUrl = 'http://localhost:8080'; // remove this if you test in refonte-api.khallasli.com

export const hotelsUrl = 'http://192.168.12.115:5014';

//export const baseUrl = 'http://192.168.12.115:8080';
export const baseFilesUrl = '5002';

export const baseImgbUrl = '5005';
//export const transactionsBaseurl = 'http://192.168.10.116:4000';
export const exportCsvFiles = '4000';
export const exportXlmFiles = '4001';
export const exportFiles = '5004';
export const transactionsBaseurl = '4000';
export const mintrouteImages = 'https://api.khallasli.com';
export const mailVerifyKey = '9171d6b8c26d48ecb6cede7fe3d5ccaa';
export const virusTotalAPiKey =
  '4ba0cf173440e1abfe00619b1af512d68cda96c8aac8306ee28b4ede45e33974';
