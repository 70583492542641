import dataAxios from '@crema/services/data/axiosData';
import {appIntl} from '@crema/utility/Utils';
import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  SHOW_MESSAGE,
  GET_ALL_BLOGS_LIST,
  GET_ALL_BLOGS_DELETED,
} from 'shared/constants/ActionTypes';

export const onGetBlog = (endpoint, actionType, params) => {
  console.log('endpoint', endpoint);
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .get('/mintroute' + endpoint, {params: params})
      .then((res) => {
        if (res.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          if (res.data.data) {
            dispatch({type: actionType, payload: res.data});
          } else {
            dispatch({type: actionType, payload: {data: [], totalPages: 0}});
          }
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong'],
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: FETCH_ERROR,
        });
      });
  };
};
export const onPostBlog = (endpoint, body) => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .post('/mintroute' + endpoint, body)
      //console.log("endpoint", body)
      .then((res) => {
        if (res.status === 200) {
          //console.log(res);
          dispatch({type: FETCH_SUCCESS});
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong'],
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: FETCH_ERROR,
          payload: 'Vous ne pouvez pas ajouter des données pour le moment',
        });
      });
  };
};
export const onDeleteBlog = (endpoint, id) => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .delete('/mintroute' + endpoint + '/' + id)
      .then((res) => {
        if (res.status === 200) {
          dispatch({type: FETCH_SUCCESS});
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong'],
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: FETCH_ERROR,
          payload: 'Vous ne pouvez pas éffectuer la suppression pour le moment',
        });
      });
  };
};

export const onPatch = (endpoint, id, body) => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .patch(`/mintroute` + endpoint + '/' + id, body)
      .then((res) => {
        if (res.status === 200) {
          if (
            res.data.message === 'Blog updated' ||
            res.data.message === 'Blog updated'
          ) {
            dispatch({type: SHOW_MESSAGE});
            dispatch({
              type: SHOW_MESSAGE,
              payload: messages['common.successful'],
            });
          } else {
            dispatch({type: FETCH_ERROR});
            dispatch({
              type: FETCH_ERROR,
              payload: messages['message.somethingWentWrong'],
            });
          }
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong'],
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR});
      });
  };
};

export const getAllBlogs = () => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .get('/mintroute/blogs')
      .then((res) => {
        // console.log("resBlog", res)
        if (res.status === 200) {
          if (res.data.message === 'Blogs retrieved') {
            dispatch({type: FETCH_SUCCESS});
            dispatch({
              type: GET_ALL_BLOGS_LIST,
              payload: res.data,
            });
          } else {
            dispatch({type: FETCH_SUCCESS});
            dispatch({
              type: GET_ALL_BLOGS_LIST,
              payload: [],
            });
          }
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong'],
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR});
      });
  };
};
export const getAllDeleted = (params) => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .get('/mintroute', {params: params})
      .then((res) => {
        if (res.status === 200) {
          if (res.data.message === 'List deleted ') {
            dispatch({type: FETCH_SUCCESS});
            dispatch({
              type: GET_ALL_BLOGS_DELETED,
              payload: [],
            });
          } else {
            dispatch({type: FETCH_SUCCESS});
            dispatch({
              type: GET_ALL_BLOGS_DELETED,
              payload: res.data.data,
            });
          }
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong'],
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR});
      });
  };
};

/* export const addGuid = (body) => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .post(`/mintroute/guide`, body)
      .then((res) => {
        if (res.status === 201) {
          dispatch({type: SHOW_MESSAGE});
          dispatch({
            type: SHOW_MESSAGE,
            payload: messages['common.successful'],
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong'],
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR});
      });
  };
}; */
/* export const onEditGuide = (body) => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .patch(`/mintroute/guide`, body)
      .then((res) => {
        if (res.status === 201) {
          dispatch({type: SHOW_MESSAGE});
          dispatch({
            type: SHOW_MESSAGE,
            payload: messages['common.successful'],
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong'],
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR});
      });
  };
}; */
