import {
  GET_ALL_MINTROUTE_HISTORY,
  GET_ALL_MINTROUTE_PRODUCTS,
  GET_ALL_MINTROUTE_TRANS,
  SET_MINTROUTE_AMOUNT,
  SET_MINTROUTE_AMOUNT_FALSE,
  SET_MINTROUTE_VOUCHER_PRINT,
  SET_PRODUCT_VIEW_TYPE,
  GET_TYPE_PRODUCT,
  GET_ALL_MINTROUTE_GUIDE,
  GET_MINTROUTE_GUIDE_DETAIL,
  GET_ALL_MINTROUTE_BRAND,
} from '../../shared/constants/ActionTypes';
export const VIEW_TYPE = Object.freeze({LIST: 1, GRID: 2});

const initialSettings = {
  viewType: VIEW_TYPE.LIST,
  products: [],
  historyTransactions: [],
  totalPages: 0,
  amountDialog: false,
  productAmount: 0,
  types: [],
  guide: [],
  guideDetails: {},
};

const mintrouteReducer = (state = initialSettings, action) => {
  switch (action.type) {
    case GET_ALL_MINTROUTE_TRANS:
      return {
        ...state,
        historyTransactions: action.payload.data,
        totalPages: action.payload.totalPages,
      };
    case GET_ALL_MINTROUTE_PRODUCTS:
      return {
        ...state,
        products: action.payload.data,
        totalPages: action.payload.totalPages,
      };
    case GET_ALL_MINTROUTE_HISTORY:
      return {
        ...state,
        historyTransactions: action.payload.data,
        totalPages: action.payload.totalPages,
      };
    case SET_PRODUCT_VIEW_TYPE:
      return {
        ...state,
        viewType: action.payload,
      };
    case SET_MINTROUTE_AMOUNT:
      return {
        ...state,
        productAmount: action.payload,
      };
    case SET_MINTROUTE_AMOUNT_FALSE:
      return {
        ...state,
        productAmount: 0,
      };
    case GET_ALL_MINTROUTE_BRAND:
      return {
        ...state,
        brands: 0,
      };
    case SET_MINTROUTE_VOUCHER_PRINT:
      return {...state, voucherData: action.payload};
    case GET_TYPE_PRODUCT:
      return {
        ...state,
        types: action.payload.data,
        totalPages: action.payload.totalPages,
      };
    case GET_ALL_MINTROUTE_GUIDE: {
      return {
        ...state,
        guide: action.payload.data,
      };
    }
    case GET_MINTROUTE_GUIDE_DETAIL: {
      return {
        ...state,
        guideDetails: action.payload,
      };
    }
    default:
      return state;
  }
};

export default mintrouteReducer;
