import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  HIDE_DIALOG,
  HIDE_MESSAGE,
  SHOW_DIALOG,
  SHOW_MESSAGE,
  TOGGLE_APP_DRAWER,
  UPDATING_CONTENT,
} from '../../shared/constants/ActionTypes';

const INIT_STATE = {
  error: '',
  loading: false,
  isAppDrawerOpen: false,
  updatingContent: false,
  message: '',
  dialog: false,
};

const commonReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_START: {
      return {...state, error: '', message: '', loading: true};
    }
    case UPDATING_CONTENT: {
      return {...state, error: '', message: '', updatingContent: true};
    }
    case FETCH_SUCCESS: {
      return {
        ...state,
        error: '',
        message: '',
        loading: false,
        updatingContent: false,
      };
    }
    case SHOW_MESSAGE: {
      setTimeout(() => {
        if (document.getElementById('messageHandler')) {
          document.getElementById('messageHandler').scrollIntoView();
        }
      }, 100);
      return {
        ...state,
        error: '',
        message: action.payload,
        loading: false,
        updatingContent: false,
      };
    }
    case FETCH_ERROR: {
      setTimeout(() => {
        if (document.getElementById('messageHandler')) {
          document.getElementById('messageHandler').scrollIntoView();
        }
      }, 100);
      return {
        ...state,
        loading: false,
        error: action.payload,
        message: '',
        updatingContent: false,
      };
    }
    case HIDE_MESSAGE: {
      return {
        ...state,
        loading: false,
        error: '',
        message: '',
        updatingContent: false,
      };
    }
    case TOGGLE_APP_DRAWER: {
      return {
        ...state,
        isAppDrawerOpen: !state.isAppDrawerOpen,
      };
    }
    case SHOW_DIALOG: {
      return {
        ...state,
        dialog: true,
      };
    }
    case HIDE_DIALOG: {
      return {
        ...state,
        dialog: false,
      };
    }
    default:
      return state;
  }
};
export default commonReducer;
