import {appIntl} from '@crema/utility/Utils';
import dataAxios from '@crema/services/data/axiosData';

import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  SHOW_MESSAGE,
} from 'shared/constants/ActionTypes';
import {fetchError} from './Common';

export const onGetAllFunction = (endpoint, actionType, params) => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .get(endpoint, {params: params})
      .then((res) => {
        if (res.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          if (res.data.data) {
            dispatch({type: actionType, payload: res.data.data});
          } else {
            dispatch({type: actionType, payload: {data: [], totalPages: 0}});
          }
        } else {
          dispatch(fetchError(messages['message.somethingWentWrong']));
        }
      })
      .catch((error) => {
        dispatch({
          type: FETCH_ERROR,
        });
      });
  };
};

export const onDeleteFunction = (endpoint, id) => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .delete(endpoint + '/' + id)
      .then((res) => {
        if (res.status === 200) {
          dispatch({type: FETCH_SUCCESS});
        } else {
          dispatch(fetchError(messages['message.somethingWentWrong']));
        }
      })
      .catch((error) => {
        dispatch({
          type: FETCH_ERROR,
          payload: 'Vous ne pouvez pas éffectuer la suppression pour le moment',
        });
      });
  };
};
export const onEditFunction = (endpoint, body, id) => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .put(endpoint + '/' + id, body)
      .then((res) => {
        if (res.status === 201) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: SHOW_MESSAGE,
            payload: res.data.message,
          });
        } else {
          dispatch(fetchError(messages['message.somethingWentWrong']));
        }
      })
      .catch((error) => {
        dispatch(fetchError());
      });
  };
};

export const onPatchFunction = (endpoint, body, id, message) => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .patch(endpoint + '/' + id, body)
      .then((res) => {
        if (res.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: SHOW_MESSAGE,
            payload: message,
          });
        } else {
          dispatch(fetchError(messages['message.somethingWentWrong']));
        }
      })
      .catch((error) => {
        dispatch(fetchError());
      });
  };
};
