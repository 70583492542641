import dataAxios from '@crema/services/data/axiosData';
import {appIntl} from '@crema/utility/Utils';
import {fetchError} from '.';
import {
  FETCH_START,
  FETCH_SUCCESS,
  GET_ALL_FACIAL,
  GET_ALL_HISTORY_TOPUP,
  GET_ALL_HISTORY_VOUCHER,
  GET_ALL_OPERATORS,
  GET_ALL_TOPUP_OPERATORS,
  GET_ALL_TYPE_OPERATOR,
  GET_DIALOG_STATUS,
  GET_INTERNET_SABBA_DATA,
  GET_USER_BALANCE,
  GET_VOUCHER_PDF,
  GET_VOUCHER_STATUS,
  SEY_EMPTY,
  SHOW_MESSAGE,
  POST_ORDER,
  GET_ORDERS,
} from '../../shared/constants/ActionTypes';

export const getAllFacial = () => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .get('/voucher/facial')
      .then((res) => {
        if (res.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: GET_ALL_FACIAL,
            payload: res.data.data.items,
          });
        } else {
          dispatch(fetchError(messages['message.somethingWentWrong']));
        }
      })
      .catch((error) => {
        dispatch(fetchError());
      });
  };
};
export const getAllOperators = () => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .get('/voucher/operator')
      .then((res) => {
        if (res.status === 200) {
          dispatch({type: FETCH_SUCCESS});

          if (res.data.message === 'Operator retrieved') {
            dispatch({
              type: GET_ALL_OPERATORS,
              payload: res.data.data.items,
            });
          } else {
            dispatch({
              type: GET_ALL_OPERATORS,
              payload: [],
            });
          }
        } else {
          dispatch(fetchError(messages['message.somethingWentWrong']));
        }
      })
      .catch((error) => {
        dispatch(fetchError());
      });
  };
};
export const getAllTopupOperators = (param) => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .get('/topUpKh/operator', {params: param})
      .then((res) => {
        if (res.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          //console.log(res, 'resOpTopup');
          if (res.data.message === 'Operators retrieved') {
            dispatch({
              type: GET_ALL_TOPUP_OPERATORS,
              payload: res.data.data.data,
            });
          } else {
            dispatch({
              type: GET_ALL_OPERATORS,
              payload: [],
            });
          }
        } else {
          dispatch(fetchError(messages['message.somethingWentWrong']));
        }
      })
      .catch((error) => {
        dispatch(fetchError());
      });
  };
};
export const getTypeOperators = () => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .get('/topUpKh/type', {params: {code: 11}})
      .then((res) => {
        if (res.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          if (res.data.message === 'Types retrieved') {
            dispatch({
              type: GET_ALL_TYPE_OPERATOR,
              payload: res.data.data.data[0],
            });
          } else {
            dispatch({
              type: GET_ALL_OPERATORS,
              payload: [],
            });
          }
        } else {
          dispatch(fetchError(messages['message.somethingWentWrong']));
        }
      })
      .catch((error) => {
        dispatch(fetchError());
      });
  };
};
export const sendVoucherComand = (service, setLoading) => {
  const {messages} = appIntl();
  return (dispatch) => {
    const body = service;
    if (body.operator === undefined || body.value === undefined) {
      dispatch(fetchError(messages['message.pleaseAddOperatorFacial']));

      setLoading(false);
    } else {
      dataAxios
        .post('/voucher/transaction', body)
        .then((data) => {
          setLoading(false);
          if (data.status === 200) {
            if (data.data.status === 'error') {
              if (data.data.code === 2000) {
                dispatch(fetchError(messages['voucher.stock.insufficient']));
              } else if (data.data.code === 5000) {
                dispatch(fetchError(messages['voucher.commande.empty']));
              } else if (data.data.code === 2001) {
                dispatch(fetchError(messages['voucher.commande.not.exist']));
              } else if (data.data.code === 2002) {
                dispatch(fetchError(messages['voucher.commande.type']));
              } else if (data.data.code === 2003) {
                dispatch(fetchError(messages['voucher.commande.out.stock']));
              } else if (data.data.code === 2004) {
                dispatch(fetchError(messages['voucher.commande.out.stock']));
              } else if (data.data.code === 2005) {
                dispatch(fetchError(messages['voucher.commande.out.stock']));
              } else if (data.data.code === 2006) {
                dispatch(fetchError(messages['voucher.commande.empty.stock']));
              }
            } else {
              //console.log('res', data);
              if (data.data.code === 2110 || data.data.code === 2100) {
                dispatch(fetchError(data.data.message));
              } else if (data.data.code === 1101) {
                dispatch({type: FETCH_SUCCESS});
                dispatch({
                  type: SHOW_MESSAGE,
                  payload: messages['voucher.transaction.pending'],
                });
              } else if (data.data.code === 2030) {
                dispatch(
                  fetchError(
                    messages['voucher.commande.limited.stock'] +
                      +' : ' +
                      Math.abs(data.data.data.number_voucher_allowed),
                  ),
                );
              } else if (data.data.code === 2031) {
                dispatch(fetchError(messages['voucher.commande.can.not']));
              } else {
                dispatch({
                  type: GET_VOUCHER_PDF,
                  payload: data.data.data?.vouchers,
                });
                dispatch({
                  type: GET_USER_BALANCE,
                  payload: data.data.data.wallet?.balance,
                });
                dispatch({
                  type: GET_VOUCHER_STATUS,
                  payload: true,
                });
              }
            }
          }
        })
        .catch((error) => {
          setLoading(false);
          dispatch(fetchError(messages['message.somethingWentWrong']));
        });
    }
  };
};

export const sendOrderComand = (service, setLoading) => {
  const {messages} = appIntl();
  return (dispatch) => {
    const body = service;

    dataAxios
      .post('/voucher/order', body)
      .then((res) => {
        if (res.status == 201) {
          dispatch({
            type: SHOW_MESSAGE,
            payload: messages['voucher.order.pending'],
          });
        }
      })
      .catch((error) => {
        dispatch(fetchError(messages['message.somethingWentWrong']));
      });
  };
};

export const getHistoryOrderComand = (params) => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .get('/voucher/order/user', {params: params})
      .then((res) => {
        if (res.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: GET_ORDERS,
            payload: res.data,
          });
        }
      })
      .catch((error) => {
        dispatch(fetchError(messages['message.somethingWentWrong']));
      });
  };
};

export const getAllVoucherHistory = (id, params) => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .get('/voucher/transaction/by_user', {params: params})
      .then((res) => {
        //console.log('get all history', res);
        if (res.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          if (res.data.message === 'List of transactions.') {
            if (res.data.data.length === 0) {
              dispatch({
                type: SEY_EMPTY,
              });
            } else {
              dispatch({
                type: GET_ALL_HISTORY_VOUCHER,
                payload: res.data.data,
              });
            }
          }
        } else {
          dispatch(fetchError(messages['message.somethingWentWrong']));
        }
      })
      .catch((error) => {
        dispatch(fetchError());
      });
  };
};
export const getAllTopupHistory = (id, params) => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .get(`/topUpKh/transaction/by_user/`, {params: params})
      .then((res) => {
        //console.log('get all history', res);
        if (res.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          if (res.data.message === 'List of Transactions') {
            if (res.data.data.length === 0) {
              dispatch({
                type: SEY_EMPTY,
              });
            } else {
              dispatch({
                type: GET_ALL_HISTORY_TOPUP,
                payload: res.data.data,
              });
            }
          }
        } else {
          dispatch(fetchError(messages['message.somethingWentWrong']));
        }
      })
      .catch((error) => {
        dispatch(fetchError());
      });
  };
};
export const get_url_list_voucher_id_export = (id, params) => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .get(`/voucher/transaction/by_user/${id}`, {params: params})
      .then((res) => {
        if (res.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          if (res.data.message === 'List of transactions.') {
            if (res.data.data.length === 0) {
              dispatch({
                type: SEY_EMPTY,
              });
            } else {
              dispatch({
                type: GET_ALL_HISTORY_VOUCHER,
                payload: res.data.data,
              });
            }
          }
        } else {
          dispatch(fetchError(messages['message.somethingWentWrong']));
        }
      })
      .catch((error) => {
        dispatch(fetchError());
      });
  };
};
export const sendTopupComand = (service, setLoading) => {
  const {messages} = appIntl();
  return (dispatch) => {
    const body = service;
    // dispatch({type: FETCH_START});
    dataAxios
      .post('/topUpKh/transaction/', body)
      .then((res) => {
        setLoading(false);
        if (res.status === 200) {
          if (res.data?.message === 'the Wallet is already updated!') {
            dispatch({type: FETCH_SUCCESS});
            // dispatch({
            //   type: SHOW_MESSAGE,
            //   payload: 'Votre recharge a été effectué avec succès',
            // });
            dispatch({
              type: GET_DIALOG_STATUS,
              payload: true,
            });
            dispatch({
              type: GET_USER_BALANCE,
              payload: res.data.data.balance,
            });
          } else if (res.data?.data?.code === 2000) {
            dispatch(fetchError(messages['wallet.insufficient']));
          } else if (res.data?.code === 2110 || res.data?.code === 2100) {
            dispatch(fetchError(res.data.message));
          } else {
            dispatch(fetchError('Transaction échouée! Veuillez réessayer'));
          }
        } else {
          dispatch(fetchError(res.data.message.message));
        }
      })
      .catch((error) => {
        setLoading(false);
        //console.log('error topup', error.response);
        if (error.response?.data?.code === 2003) {
          dispatch(
            fetchError(
              'Ce service est non disponible pour le moment! Veuillez réessayer plus tard',
            ),
          );
        } else if (error.response?.data?.message === 'Empty stock') {
          dispatch(
            fetchError('La transaction est en rupture de stock pour le moment'),
          );
        } else {
          dispatch(
            fetchError(
              'Vous ne pouvez pas effectuer cette transaction pour le moment',
            ),
          );
        }
      });
  };
};
export const getInternetSabbaData = (params) => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .get(`/topupkh/config/saba`, {params: params})
      .then((res) => {
        if (res.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          if (res.data.code === 2202) {
            dispatch({
              type: GET_INTERNET_SABBA_DATA,
              payload: res.data.data,
            });
          }
        } else {
          dispatch(fetchError(messages['message.somethingWentWrong']));
        }
      })
      .catch((error) => {
        dispatch(fetchError());
      });
  };
};
