import IntlMessages from '@crema/utility/IntlMessages';
import {Box, Button} from '@material-ui/core';
import moment from 'moment';
import React from 'react';
import {fetchSuccess} from 'redux/actions';
import {FETCH_ERROR} from './ActionTypes';

export const convertServiceSupplierArrayToObject = (array, key) => {
  const initialValue = {};
  ////console.log(array,key,"heneeee")
  return array.reduce((obj, item) => {
    ////console.log(obj,item,"heneeee")
    return {
      ...obj,
      [item[key]]: item.id,
    };
  }, initialValue);
};
export const filtringColumnandRowFunction = (dataRow, columns) => {
  const initialValue = {};
  ////console.log(array,key,"heneeee")
  return columns.reduce((obj, col) => {
    // //console.log(obj,col,"heneeee")
    return {
      ...obj,
      [col.dataField]: dataRow[col.dataField],
    };
  }, initialValue);
};
var currentdate = new Date();
currentdate.setMinutes(currentdate.getMinutes() + 10);

export const currentDate = (sp) => {
  //today = new Date();
  var dd = currentdate.getDate();
  var mm = currentdate.getMonth() + 1; //As January is 0.
  var yyyy = currentdate.getFullYear();

  if (dd < 10) dd = '0' + dd;
  if (mm < 10) mm = '0' + mm;
  return yyyy + sp + mm + sp + dd;
};
export const currentDateHotel = (date) => {
  //today = new Date();
  const d = date.split('-');
  return d[2] + '-' + d[1] + '-' + d[0];
};
export const tomorrowDate = (sp) => {
  let date = new Date();
  date.setDate(date.getDate() + 1);
  var dd = date.getDate();
  var mm = date.getMonth() + 1; //As January is 0.
  var yyyy = date.getFullYear();

  if (dd < 10) dd = '0' + dd;
  if (mm < 10) mm = '0' + mm;
  return yyyy + sp + mm + sp + dd;
};
export const formatDate = (date) => {
  return date.slice(0, 10);
};
export const formatDateExtract = (date) => {
  var d = new Date(date);
  d = moment(d).format();
  return d.slice(0, 10) + ', ' + d.slice(11, 13) + ':' + d.slice(14, 16);
};
export const formatDateHotels = (inputDate) => {
  const date = new Date(inputDate);

  //extract the parts of the date
  let dd = date.getDate();
  let mm = date.getMonth() + 1;
  let yyyy = date.getFullYear();
  //date.slice(0, 10);
  // var dd = date.slice(8, 10);
  // var mm = date.slice(5, 6); //As January is 0.
  // var yyyy = date.slice(0, 3);

  if (dd < 10) dd = '0' + dd;
  if (mm < 10) mm = '0' + mm;
  return dd + '-' + mm + '-' + yyyy;
};
export const getOperatorImgbyName = (operatorName) => {
  const opert = operatorName?.replace(/\s/g, '');
  var operator = {
    orange: '/assets/images/Operateurs/orange.jpg',
    ooredoo: '/assets/images/Operateurs/ooredooNEw.jpg',
    telecom: '/assets/images/Operateurs/tt.jpg',
    télécom: '/assets/images/Operateurs/tt.jpg',
    elissa: '/assets/images/Operateurs/tt.jpg',
    ORANGE: '/assets/images/Operateurs/orange.jpg',
    OOREDOO: '/assets/images/Operateurs/ooredooNEw.jpg',
    TELECOM: '/assets/images/Operateurs/tt.jpg',
    LYCAMOBILE: '/assets/images/Operateurs/lyca.png',
    ASELMOBILE: '/assets/images/Operateurs/AselMobile.png',
  };
  return operator[opert];
};

export const getBankImgbyName = (bankName) => {
  var bank = {
    CASH: '/assets/images/organismes/Billet4.jpeg',

    STB: '/assets/images/organismes/stb bank.jpg',
    ATTIJERI: '/assets/images/organismes/BAnk attijari.jpg',
    ATTIJARI: '/assets/images/organismes/BAnk attijari.jpg',
    BH: '/assets/images/organismes/BH.png',
    POSTE: '/assets/images/organismes/poste.jpg',
    BIAT: '/assets/images/organismes/biat.jpg',
    ZITOUNA: '/assets/images/organismes/banque zitouna.jpg',
  };
  return bank[bankName];
};
export const getLabel = (row, arrayValues) => {
  let label;
  if (arrayValues) {
    arrayValues.filter((value) => {
      if (value.id === row) {
        ////console.log('filter', operateur.label);
        label = value.label;
        return label;
      }
    });
    return label;
  } else return null;
};
export const voucherTransactionsStatus = (statusCode) => {
  var status = {
    false: (
      <Box
        style={{
          pointerEvents: 'none',
          fontSize: '15px',
          width: '100px',
          padding: '10px',
          textAlign: 'center',
          borderRadius: '5px',
          fontWeight: 500,
          color: getTransactionsStatusColor('1000'),
          backgroundColor: getTransactionsStatusColor('1000') + '20',
        }}>
        <IntlMessages id='common.echoue' />
      </Box>
    ),
    true: (
      <Box
        style={{
          pointerEvents: 'none',
          fontSize: '15px',
          width: '100px',
          padding: '10px',
          textAlign: 'center',
          borderRadius: '5px',
          fontWeight: 500,
          color: getTransactionsStatusColor('1011'),
          backgroundColor: getTransactionsStatusColor('1011') + '20',
        }}>
        Succès
      </Box>
    ),
    1000: (
      <Button
        style={{
          pointerEvents: 'none',
          padding: 5,
          width: 100,
          color: getTransactionsStatusColor('1000'),
          backgroundColor: getTransactionsStatusColor('1000') + '22',
        }}>
        Echec
      </Button>
    ),
    1011: (
      <Button
        style={{
          pointerEvents: 'none',
          padding: 5,
          width: 100,
          color: getTransactionsStatusColor('1011'),
          backgroundColor: getTransactionsStatusColor('1011') + '22',
        }}>
        Succès
      </Button>
    ),
    1101: (
      <Button
        style={{
          pointerEvents: 'none',
          padding: 5,
          width: 100,
          color: getTransactionsStatusColor('1101'),
          backgroundColor: getTransactionsStatusColor('1101') + '22',
        }}>
        En cours
      </Button>
    ),
    1001: (
      <Button
        style={{
          pointerEvents: 'none',
          padding: 5,
          width: 100,
          color: getTransactionsStatusColor('1001'),
          backgroundColor: getTransactionsStatusColor('1001') + '22',
        }}>
        Rejeté
      </Button>
    ),
    1111: (
      <Button
        style={{
          pointerEvents: 'none',
          padding: 5,
          width: 100,
          color: getTransactionsStatusColor('1111'),
          backgroundColor: getTransactionsStatusColor('1111') + '22',
        }}>
        Succès
      </Button>
    ),
    1010: (
      <Button
        style={{
          pointerEvents: 'none',
          padding: 5,
          width: 100,
          color: getTransactionsStatusColor('1101'),
          backgroundColor: getTransactionsStatusColor('1101') + '22',
        }}>
        En cours
      </Button>
    ),
  };
  return status[statusCode];
};
export const formatDateAndTime = (date) => {
  var d = new Date(date);
  d = moment(d).format();
  return date ? d.slice(0, 10) + ' , ' + d.slice(11, 16) : date;
};
export const AlimenterUserStatus = (statusCode) => {
  var status = {
    2: (
      <Button
        style={{
          pointerEvents: 'none',
          padding: 5,
          width: 100,
          color: getTransactionsStatusColor('1000'),
          backgroundColor: getTransactionsStatusColor('1000') + '22',
        }}>
        Rejeté
      </Button>
    ),
    3: (
      <Button
        style={{
          pointerEvents: 'none',
          padding: 5,
          width: 100,
          color: getTransactionsStatusColor('1011'),
          backgroundColor: getTransactionsStatusColor('1011') + '22',
        }}>
        Accepté
      </Button>
    ),
    1: (
      <Button
        style={{
          pointerEvents: 'none',
          padding: 5,
          width: 100,
          color: getTransactionsStatusColor('1101'),
          backgroundColor: getTransactionsStatusColor('1101') + '22',
        }}>
        En attente
      </Button>
    ),
  };
  return status[statusCode];
};
export const paymentfactureStatus = (statusCode) => {
  var status = {
    0: (
      <Button
        style={{
          pointerEvents: 'none',
          padding: 5,
          width: 100,
          color: getTransactionsStatusColor('1000'),
          backgroundColor: getTransactionsStatusColor('1000') + '22',
        }}>
        Echec
      </Button>
    ),
    1: (
      <Button
        style={{
          pointerEvents: 'none',
          padding: 5,
          width: 100,
          color: getTransactionsStatusColor('1011'),
          backgroundColor: getTransactionsStatusColor('1011') + '22',
        }}>
        Succès
      </Button>
    ),
    2: (
      <Button
        style={{
          pointerEvents: 'none',
          padding: 5,
          width: 100,
          color: getTransactionsStatusColor('1101'),
          backgroundColor: getTransactionsStatusColor('1101') + '22',
        }}>
        EN attente
      </Button>
    ),
    1101: (
      <Button
        style={{
          pointerEvents: 'none',
          padding: 5,
          width: 100,
          color: getTransactionsStatusColor('1011'),
          backgroundColor: getTransactionsStatusColor('1011') + '22',
        }}>
        Succès
      </Button>
    ),
  };
  return status[statusCode];
};
const getTransactionsStatusColor = (status) => {
  switch (status) {
    case '1101': {
      return '#e69d07';
    }
    case '1011': {
      return '#3db87c';
    }
    case '1000': {
      return '#F84E4E';
    }
    case '1111': {
      return '#3db87c';
    }
    case '1001': {
      return '#F84E4E';
    }
    default: {
      return '';
    }
  }
};
export const CodeOrganisme = (props) => {
  if (props === 'steg') return 'STEG';
  if (props === 'tunisie-autoroute') return 'AUTOROUTE';
  if (props === 'amende-radar') return 'RADAR';
  if (props === 'cnss') return 'CNSS';
  if (props === 'telecom') return 'TELECOM';
  if (props === 'orange') return 'ORANGE';
  if (props === 'ooredoo') return 'OOREDOO';
  if (props === 'sonede') return 'SONEDE';
  // if(props === "8")  return "";
  // if(props === "9")  return "";
  // if(props === "10")  return "";
  // if(props === "11")  return "";
  // if(props === "12")  return "";
  // if(props === "13")  return "";
  // if(props === "14")  return "";
  // if(props === "15")  return "";
};
export const LogoOrganisme = (props) => {
  if (props === 'steg' || props === '0701' || props === 'STEG')
    return '/assets/images/organismes/steg.jpg';
  if (props === 'CNSS' || props === 'cnss')
    return '/assets/images/organismes/cnss.jpg';
  if (props === 'amende-radar' || props === '0502' || props === 'RADAR')
    return '/assets/images/organismes/amendeRadar.png';
  if (
    props === 'tunisie-autoroute' ||
    props === '0206' ||
    props === 'AUTOUROUTE' ||
    props === 'AUTOROUTE'
  )
    return '/assets/images/organismes/tunisieAutoroute.png';
  if (
    props === 'telecom' ||
    props === '0151' ||
    props === '0152' ||
    props === '0153' ||
    props === '0154' ||
    props === '0380' ||
    props === '0381' ||
    props === '0382' ||
    props === '0383' ||
    props === '0384' ||
    props === '0385' ||
    props === 'TELECOM'
  )
    return '/assets/images/organismes/tt.jpg';
  if (
    props === 'orange' ||
    props === '0737' ||
    props === '0763' ||
    props === 'ORANGE'
  )
    return '/assets/images/organismes/orange.jpg';
  if (props === 'SONEDE') return '/assets/images/organismes/sonede.jpg';
  if (props === 'TOPNET') return '/assets/images/organismes/topnet.jpg';
  if (props === 'ooredoo' || props === '0777' || props === 'OOREDOO')
    return '/assets/images/organismes/ooredooNEw.jpg';
  if (props === 'topnet' || props === '7' || props === '0770')
    return '/assets/images/organismes/topnet.jpg';
  if (props === '8')
    return process.env.PUBLIC_URL + '/images/LogosPaymentFacture/globalnet.jpg';
  if (props === '9')
    return process.env.PUBLIC_URL + '/images/LogosPaymentFacture/cfe.jpg';
  if (props === '10')
    return process.env.PUBLIC_URL + '/images/LogosPaymentFacture/advans.jpg';
  if (props === '11')
    return process.env.PUBLIC_URL + '/images/LogosPaymentFacture/tayssir.jpg';
  if (props === '12')
    return process.env.PUBLIC_URL + '/images/LogosPaymentFacture/snit.jpg';
  if (props === '13')
    return process.env.PUBLIC_URL + '/images/LogosPaymentFacture/microcreo.jpg';
  if (props === '14')
    return process.env.PUBLIC_URL + '/images/LogosPaymentFacture/ttn.jpg';
  if (props === '15')
    return process.env.PUBLIC_URL + '/images/LogosPaymentFacture/zitouna.jpg';
};
export const Codebank = (props) => {
  if (props === '01') return 'Biat';
  if (props === '02') return 'Tijarri bank';
  if (props === '03') return 'Zitouna';
};
export const cities = [
  {
    value: 'Ariana',
    label: 'Ariana',
  },
  {
    value: 'Ben Arous',
    label: 'Ben Arous',
  },
  {
    value: 'Bizerte',
    label: 'Bizerte',
  },
  {
    value: 'Béja',
    label: 'Béja',
  },
  {
    value: 'Gafsa',
    label: 'Gafsa',
  },
  {
    value: 'Gabès',
    label: 'Gabès',
  },
  {
    value: 'Jendouba',
    label: 'Jendouba',
  },
  {
    value: 'Kairouan',
    label: 'Kairouan',
  },
  {
    value: 'Kasserine',
    label: 'Kasserine',
  },
  {
    value: 'Kebili',
    label: 'Kebili',
  },
  {
    value: 'Kef',
    label: 'Kef',
  },
  {
    value: 'Mahdia',
    label: 'Mahdia',
  },
  {
    value: 'Manouba',
    label: 'Manouba',
  },
  {
    value: 'Mednine',
    label: 'Mednine',
  },
  {
    value: 'Monastir',
    label: 'Monastir',
  },
  {
    value: 'Nabeul',
    label: 'Nabeul',
  },
  {
    value: 'Siliana',
    label: 'Siliana',
  },
  {
    value: 'Sidi Bouzid',
    label: 'Sidi Bouzid',
  },
  {
    value: 'Sfax',
    label: 'Sfax',
  },
  {
    value: 'Sousse',
    label: 'Sousse',
  },
  {
    value: 'Tataouine',
    label: 'Tataouine',
  },

  {
    value: 'Tozeur',
    label: 'Tozeur',
  },
  {
    value: 'Tunis',
    label: 'Tunis',
  },
  {
    value: 'Zaghouan',
    label: 'Zaghouan',
  },
];

export const citiesArabe = [
  {
    value: 'ولاية أريانة',
    label: 'ولاية أريانة',
  },
  {
    value: 'ولاية باجة',
    label: 'ولاية باجة',
  },
  {
    value: 'ولاية بن عروس',
    label: 'ولاية بن عروس',
  },
  {
    value: 'ولاية بنزرت',
    label: 'ولاية بنزرت',
  },
  {
    value: 'ولاية تطاوين',
    label: 'ولاية تطاوين',
  },
  {
    value: 'ولاية توزر',
    label: 'ولاية توزر',
  },
  {
    value: 'ولاية تونس',
    label: 'ولاية تونس',
  },
  {
    value: 'ولاية جندوبة',
    label: 'ولاية جندوبة',
  },
  {
    value: 'ولاية زغوان',
    label: 'ولاية زغوان',
  },
  {
    value: 'ولاية سليانة',
    label: 'ولاية سليانة',
  },
  {
    value: 'ولاية سوسة',
    label: 'ولاية سوسة',
  },
  {
    value: 'ولاية سيدي بوزيد',
    label: 'ولاية سيدي بوزيد',
  },
  {
    value: 'ولاية صفاقس',
    label: 'ولاية صفاقس',
  },
  {
    value: 'ولاية قابس',
    label: 'ولاية قابس',
  },
  {
    value: 'ولاية قبلي',
    label: 'ولاية قبلي',
  },
  {
    value: 'ولاية القصرين',
    label: 'ولاية القصرين',
  },
  {
    value: 'ولاية قفصة',
    label: 'ولاية قفصة',
  },
  {
    value: 'ولاية القيروان',
    label: 'ولاية القيروان',
  },
  {
    value: 'ولاية الكاف',
    label: 'ولاية الكاف',
  },
  {
    value: 'ولاية مدنين',
    label: 'ولاية مدنين',
  },
  {
    value: 'ولاية المنستير',
    label: 'ولاية المنستير',
  },

  {
    value: 'ولاية منوبة',
    label: 'ولاية منوبة',
  },
  {
    value: 'ولاية المهدية',
    label: 'ولاية المهدية',
  },
  {
    value: 'ولاية نابل',
    label: 'ولاية نابل',
  },
];
export const CodeToOp = (props) => {
  if (props === 'Op1') return 'Ooredoo';
  if (props === 'Op2') return 'Orange';
  if (props === 'Op3') return 'Telecom';
};

export const convertmontant = (props) => {
  if (props === '1000') return '1';
  if (props === '5000') return '5';
  if (props === '10000') return '10';
};
export const HowTochargeTickets = (props) => {
  if (props === 'Ooredoo' || props === 'Op1')
    return 'taper *101* code de recharge # ';
  if (props === 'Orange' || props === 'Op2')
    return 'taper *100* code de recharge # ';
  if (props === 'Telecom' || props === 'Op3')
    return 'taper *123* code de recharge # ';
};

export const uploadMultipleFiles = async (e, dispatch) => {
  if (e.target.files[0]?.size > 2000000) {
    dispatch({
      type: FETCH_ERROR,
      payload: 'Veuillez choisir une taille inférieure à 2MO',
    });
  }
  if (e.target.files[0]) {
    if (
      e.target.files[0].type !== 'image/png' &&
      e.target.files[0].type !== 'image/jpg' &&
      e.target.files[0].type !== 'image/jpeg' &&
      e.target.files[0].type !== 'application/pdf'
    ) {
      //console.log('e.target.files[0].type', e.target.files[0].type);
      dispatch({
        type: FETCH_ERROR,
        payload:
          'Seuls les formats suivants sont acceptés : .jpeg, .jpg, .png et .pdf',
      });
    } else {
      dispatch(fetchSuccess());
      e.preventDefault();
      let f;
      f = await getBase64(e.target.files[0]);
      return f;
    }
  }
};
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}
export const mintrouteStatus = (statusCode) => {
  var status = {
    0: (
      <Button
        style={{
          pointerEvents: 'none',
          padding: 5,
          width: 100,
          color: getTransactionsStatusColor('1000'),
          backgroundColor: getTransactionsStatusColor('1000') + '22',
        }}>
        Echec
      </Button>
    ),
    1: (
      <Button
        style={{
          pointerEvents: 'none',
          padding: 5,
          width: 100,
          color: getTransactionsStatusColor('1011'),
          backgroundColor: getTransactionsStatusColor('1011') + '22',
        }}>
        Succès
      </Button>
    ),
    2: (
      <Button
        style={{
          pointerEvents: 'none',
          padding: 5,
          width: 100,
          color: getTransactionsStatusColor('1101'),
          backgroundColor: getTransactionsStatusColor('1101') + '22',
        }}>
        EN attente
      </Button>
    ),
  };
  return status[statusCode];
};
export const getServiceSupp = (serviceSupp, amount) => {
  if (amount === 5) {
    if (serviceSupp === 'VoucherTT') {
      return 'VOUCHER_TT_5DT';
    } else if (serviceSupp === 'VoucherOO') {
      return 'VOUCHER_OO_5DT';
    } else if (serviceSupp === 'VoucherOR') {
      return 'VOUCHER_OR_5DT';
    } else if (
      serviceSupp === 'VOUCHER_ASEL' ||
      serviceSupp === 'VoucherASEL'
    ) {
      return 'VOUCHER_ASEL_5DT';
    } else if (
      serviceSupp === 'VOUCHER_LYCA' ||
      serviceSupp === 'VoucherLYCA'
    ) {
      return 'VOUCHER_LYCA_5DT';
    }
  } else return serviceSupp;
};
export const statusDemandPos = (statusCode) => {
  var status = {
    1: (
      <Box
        style={{
          pointerEvents: 'none',
          fontSize: '15px',
          width: '100px',
          padding: '10px',
          textAlign: 'center',
          borderRadius: '5px',
          fontWeight: 500,
          color: getTransactionsStatusColor('1101'),
          backgroundColor: getTransactionsStatusColor('1101') + '20',
        }}>
        En attente
      </Box>
    ),
    2: (
      <Box
        style={{
          pointerEvents: 'none',
          fontSize: '15px',
          width: '100px',
          padding: '10px',
          textAlign: 'center',
          borderRadius: '5px',
          fontWeight: 500,
          color: getTransactionsStatusColor('1000'),
          backgroundColor: getTransactionsStatusColor('1000') + '20',
        }}>
        Rejeté
      </Box>
    ),
    3: (
      <Box
        style={{
          pointerEvents: 'none',
          fontSize: '15px',
          width: '100px',
          padding: '10px',
          textAlign: 'center',
          borderRadius: '5px',
          fontWeight: 500,
          color: getTransactionsStatusColor('1011'),
          backgroundColor: getTransactionsStatusColor('1011') + '20',
        }}>
        Accepté
      </Box>
    ),
  };
  return status[statusCode];
};
//  for reclmation Module
export const uploadMultipleFileReclamation = async (e) => {
  e.preventDefault();
  let f;
  f = await getBase64(e.target.files[0]);
  return f;
};
const getTransactionReclamationStatus = (status) => {
  switch (status) {
    case '0': {
      return '#CCCC00';
    }
    case '1': {
      return '#e69d07';
    }
    case '2': {
      return '#3DB87C';
    }
    case '3': {
      return '#f84e4e';
    }
    case '4': {
      return '#003255';
    }
  }
};
export const ReclamationEtat = (statusCode) => {
  var etat = {
    1: (
      <Box
        style={{
          pointerEvents: 'none',
          fontSize: '15px',
          paddingLeft: '10px',
          paddingRight: '10px',
          textAlign: 'center',
          borderRadius: '5px',
          fontWeight: 500,
          color: getTransactionReclamationStatus('1'),
          backgroundColor: getTransactionReclamationStatus('1') + '20',
        }}>
        En cours de traitement
      </Box>
    ),
    0: (
      <Box
        style={{
          pointerEvents: 'none',
          fontSize: '15px',
          paddingLeft: '10px',
          paddingRight: '10px',
          textAlign: 'center',
          borderRadius: '5px',
          fontWeight: 500,
          color: getTransactionReclamationStatus('0'),
          backgroundColor: getTransactionReclamationStatus('0') + '20',
        }}>
        En Attente
      </Box>
    ),
    2: (
      <Box
        style={{
          pointerEvents: 'none',
          fontSize: '15px',
          paddingLeft: '10px',
          paddingRight: '10px',
          textAlign: 'center',
          borderRadius: '5px',
          fontWeight: 500,
          color: getTransactionReclamationStatus('2'),
          backgroundColor: getTransactionReclamationStatus('2') + '20',
        }}>
        Traité
      </Box>
    ),
    3: (
      <Box
        style={{
          pointerEvents: 'none',
          fontSize: '15px',
          paddingLeft: '10px',
          paddingRight: '10px',
          textAlign: 'center',
          borderRadius: '5px',
          fontWeight: 500,
          color: getTransactionReclamationStatus('3'),
          backgroundColor: getTransactionReclamationStatus('3') + '20',
        }}>
        Annulée
      </Box>
    ),
    4: (
      <Box
        style={{
          pointerEvents: 'none',
          fontSize: '15px',
          paddingLeft: '10px',
          paddingRight: '10px',
          textAlign: 'center',
          borderRadius: '5px',
          fontWeight: 500,
          color: getTransactionReclamationStatus('4'),
          backgroundColor: getTransactionReclamationStatus('4') + '20',
        }}>
        Cloturée
      </Box>
    ),
  };
  return etat[statusCode];
};

export const getMessageByCode = (code) => {
  switch (code) {
    case 'TPE': {
      return 'Problème TPE';
    }
    case 'VCI': {
      return 'Problème technique lié à impression des vouchers';
    }
    case 'SWA': {
      return 'Problème de solde Wallet';
    }
    case 'VCU': {
      return 'Code voucher utilisé';
    }
  }
};
