import {ProductColors} from 'modules/home/Pages/slider/data';
import {
  GET_ENABLED_SERVICES,
  GET_USER_BALANCE,
  GET_USER_COMMISSIONS,
  GET_USER_STATS,
  GET_USER_STATS_BYMONTH,
  SET_AUTH_TOKEN,
  SET_IS_FIRST_CONNECTION,
  SET_OPEN_CHANGE_PASSWORD_DIALOG,
  SET_RECIEVED_CODE_FROM_EMAIL,
  SHOW_MESSAGE_SUBSCRIPTION,
  SIGNOUT_AUTH_SUCCESS,
  UPDATE_AUTH_USER,
  GET_ALL_REGIONS,
  GET_ALL_ACTIVITY,
} from '../../shared/constants/ActionTypes';

const INIT_STATE = {
  user: null,
  resultMessage: '',
  setChangePasswordDialogOpen: false,
  recivedCodeValidationInfo: {},
  token: null,
  services: [],
  activity: [],
  region: [],
  stats: [
    // {
    //   name: 'Paymee',
    //   amount: 0,
    //   img: '/assets/images/icons/image1.png',
    //   color: ProductColors.LIGHT_PINK,
    // },
    // {
    //   name: 'Voucher',
    //   amount: 0,
    //   img: '/assets/images/icons/image3.png',
    //   color: ProductColors.PARROT,
    // },
    // {
    //   name: 'Poste paymee',
    //   amount: 0,
    //   img: '/assets/images/icons/image4.png',
    //   color: ProductColors.BLUE,
    // },
    // {
    //   name: 'Topnet',
    //   amount: 0,
    //   img: '/assets/images/icons/image6.png',
    //   color: ProductColors.PINK,
    // },

    // {
    //   name: 'Poste recharge',
    //   amount: 0,
    //   img: '/assets/images/icons/image1.png',
    //   color: ProductColors.GREY,
    // },
    {
      name: 'Topup',
      amount: 0,
      img: '/assets/images/icons/recharge.png',
      color: ProductColors.LIGHT_GREEN,
    },
    {
      name: 'Voucher',
      amount: 0,
      img: '/assets/images/icons/voucher.png',
      color: ProductColors.LIGHT_blue,
    },
    {
      name: 'Paiement facture',
      amount: 0,
      img: '/assets/images/icons/invoice.png',
      color: ProductColors.GREY,
    },

    // {
    //   name: 'Paymee',
    //   amount: 0,
    //   img: '/assets/images/icons/money.png',
    //   color: ProductColors.BLUE,
    // },
    {
      name: 'Recharge carte',
      amount: 0,
      img: '/assets/images/icons/cards.png',
      color: ProductColors.LIGHT_PINK,
    },
    {
      name: 'Topnet',
      amount: 0,
      img: '/assets/images/icons/world-grid.png',
      color: ProductColors.PINK,
    },
  ],
  monthStats: [
    {
      month: 'Jan',
      voucher: 0,
      topnet: 0,
      paymee: 0,
    },
    {
      month: 'Fév',
      voucher: 0,
      topnet: 0,
      paymee: 0,
    },
    {
      month: 'Mar',

      voucher: 0,
      topnet: 0,
      paymee: 0,
    },
    {
      month: 'Avr',
      voucher: 0,
      topnet: 0,
      paymee: 0,
    },
    {
      month: 'Mai',
      voucher: 0,
      topnet: 0,
      paymee: 0,
    },
    {
      month: 'Jun',
      voucher: 0,
      topnet: 0,
      paymee: 0,
    },
    {
      month: 'Jul',
      voucher: 0,
      topnet: 0,
      paymee: 0,
    },
    {
      month: 'Aoû',
      voucher: 0,
      topnet: 0,
      paymee: 0,
    },
    {
      month: 'Sep',
      voucher: 0,
      topnet: 0,
      paymee: 0,
    },
    {
      month: 'Oct',
      voucher: 0,
      topnet: 0,
      paymee: 0,
    },
    {
      month: 'Nov',
      voucher: 0,
      topnet: 0,
      paymee: 0,
    },
    {
      month: 'Déc',
      voucher: 0,
      topnet: 0,
      paymee: 0,
    },
  ],
  statsTr: [
    {
      name: 'Voucher',
      transaction: 0,
      img: '/assets/images/dashboard/application_icon.png',
      color: ProductColors.PARROT,
    },
    {
      name: 'Topup',
      transaction: 0,
      img: '/assets/images/dashboard/application_icon.png',
      color: ProductColors.PINK,
    },
    {
      name: 'Poste paiement',
      transaction: 0,
      img: '/assets/images/dashboard/application_icon.png',
      color: ProductColors.BLUE,
    },
    {
      name: 'Poste recharge',
      transaction: 0,
      img: '/assets/images/dashboard/application_icon.png',
      color: ProductColors.GREY,
    },
  ],
  transactions: [
    {
      name: 'Jan',
      value: 0,
      color: 'green',
      Topnet: 0,
      Paymee: 0,
      Voucher: 0,
    },
    {
      name: 'Jan',
      value: 0,
      color: 'green',
      Topnet: 0,
      Paymee: 0,
      Voucher: 0,
    },

    {
      name: 'Fév',
      value: 0,
      color: 'gray',
      Topnet: 0,
      Paymee: 0,
      Voucher: 0,
    },
    {
      name: 'Mar',
      value: 0,
      color: 'gray',
      Topnet: 0,
      Paymee: 0,
      Voucher: 0,
    },
    {
      name: 'Avr',
      value: 0,
      color: 'gray',
      Topnet: 0,
      Paymee: 0,
      Voucher: 0,
    },
    {
      name: 'Mai',
      value: 0,
      color: 'gray',
      Topnet: 0,
      Paymee: 0,
      Voucher: 0,
    },
    {
      name: 'Jun',
      value: 0,
      color: 'gray',
      Topnet: 0,
      Paymee: 0,
      Voucher: 0,
    },
    {
      name: 'Jul',
      value: 0,
      color: 'blue',
      Topnet: 0,
      Paymee: 0,
      Voucher: 0,
    },
    {
      name: 'Aoû',
      value: 0,
      color: 'gray',
      Topnet: 0,
      Paymee: 0,
      Voucher: 0,
    },
    {
      name: 'Sep',
      value: 0,
      color: 'gray',
      Topnet: 0,
      Paymee: 0,
      Voucher: 0,
    },
    {
      name: 'Oct',
      value: 0,
      color: 'red',
      Topnet: 0,
      Paymee: 0,
      Voucher: 0,
    },
    {
      name: 'Nov',
      value: 0,
      color: 'gray',
      Topnet: 0,
      Paymee: 0,
      Voucher: 0,
    },
    {
      name: 'Déc',
      value: 0,
      color: 'orange',
      Topnet: 0,
      Paymee: 0,
      Voucher: 0,
    },
  ],
  commissions: {
    Topup: 0,
    Voucher: 0,
    Wallet: 0,
    Postal: 0,
  },
  balance: 0,
};

const authReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ALL_ACTIVITY: {
      return {
        ...state,
        activity: action.payload.data,
      };
    }
    case GET_ALL_REGIONS: {
      return {
        ...state,
        region: action.payload,
      };
    }
    case UPDATE_AUTH_USER: {
      return {
        ...state,
        user: action.payload,
      };
    }
    case SET_IS_FIRST_CONNECTION: {
      return {
        ...state,
        isUserFirstConnection: action.payload,
      };
    }
    case SET_RECIEVED_CODE_FROM_EMAIL: {
      return {
        ...state,
        recivedCodeValidationInfo: action.payload,
      };
    }
    case SET_OPEN_CHANGE_PASSWORD_DIALOG: {
      return {
        ...state,
        setChangePasswordDialogOpen: action.payload,
      };
    }
    case SHOW_MESSAGE_SUBSCRIPTION: {
      return {
        ...state,
        resultMessage: action.payload,
      };
    }
    case SIGNOUT_AUTH_SUCCESS: {
      return {
        ...state,
        user: null,
      };
    }
    case SET_AUTH_TOKEN: {
      return {
        ...state,
        token: action.payload,
      };
    }
    case GET_USER_BALANCE: {
      return {
        ...state,
        balance: Math.round(action.payload * 1000) / 1000,
      };
    }
    case GET_USER_COMMISSIONS: {
      return {
        ...state,
        commissions: {
          Topup: Number(action.payload[0].intervenant[0].commission.toFixed(3)),
          Voucher: Number(
            action.payload[1].intervenant[0].commission.toFixed(3),
          ),
          Wallet: Number(
            action.payload[2].intervenant[0].commission.toFixed(3),
          ),
          Postal: Number(
            action.payload[3].intervenant[0].commission.toFixed(3),
          ),
        },
      };
    }
    case GET_USER_STATS_BYMONTH: {
      return {
        ...state,
        monthStats: [
          {
            month: 'Jan',
            voucher: action.payload.Voucher[0].amount,
            topnet: action.payload.Topnet[0].amount,
            paymee: action.payload.Paymee[0].amount,
          },
          {
            month: 'Fév',
            voucher: action.payload.Voucher[1].amount,
            topnet: action.payload.Topnet[1].amount,
            paymee: action.payload.Paymee[1].amount,
          },
          {
            month: 'Mar',

            voucher: action.payload.Voucher[2].amount,
            topnet: action.payload.Topnet[2].amount,
            paymee: action.payload.Paymee[2].amount,
          },
          {
            month: 'Avr',
            voucher: action.payload.Voucher[3].amount,
            topnet: action.payload.Topnet[3].amount,
            paymee: action.payload.Paymee[3].amount,
          },
          {
            month: 'Mai',
            voucher: action.payload.Voucher[4].amount,
            topnet: action.payload.Topnet[4].amount,
            paymee: action.payload.Paymee[4].amount,
          },
          {
            month: 'Jun',
            voucher: action.payload.Voucher[5].amount,
            topnet: action.payload.Topnet[5].amount,
            paymee: action.payload.Paymee[5].amount,
          },
          {
            month: 'Jul',
            voucher: action.payload.Voucher[6].amount,
            topnet: action.payload.Topnet[6].amount,
            paymee: action.payload.Paymee[6].amount,
          },
          {
            month: 'Aoû',
            voucher: action.payload.Voucher[7].amount,
            topnet: action.payload.Topnet[7].amount,
            paymee: action.payload.Paymee[7].amount,
          },
          {
            month: 'Sep',
            voucher: action.payload.Voucher[8].amount,
            topnet: action.payload.Topnet[8].amount,
            paymee: action.payload.Paymee[8].amount,
          },
          {
            month: 'Oct',
            voucher: action.payload.Voucher[9].amount,
            topnet: action.payload.Topnet[9].amount,
            paymee: action.payload.Paymee[9].amount,
          },
          {
            month: 'Nov',
            voucher: action.payload.Voucher[10].amount,
            topnet: action.payload.Topnet[10].amount,
            paymee: action.payload.Paymee[10].amount,
          },
          {
            month: 'Déc',
            voucher: action.payload.Voucher[11].amount,
            topnet: action.payload.Topnet[11].amount,
            paymee: action.payload.Paymee[11].amount,
          },
        ],
        transactions: [
          {
            name: 'Jan',
            value: action.payload.Topnet[0].transaction,
            color: 'green',
            Topnet: action.payload.Topnet[0].transaction,
            Paymee: action.payload.Paymee[0].transaction,
            Voucher: action.payload.Voucher[0].transaction,
          },
          {
            name: 'Jan',
            value: action.payload.Topnet[0].transaction,
            color: 'green',
            Topnet: action.payload.Topnet[0].transaction,
            Paymee: action.payload.Paymee[0].transaction,
            Voucher: action.payload.Voucher[0].transaction,
          },

          {
            name: 'Fév',
            value: action.payload.Paymee[1].transaction,
            color: 'gray',
            Topnet: action.payload.Topnet[1].transaction,
            Paymee: action.payload.Paymee[1].transaction,
            Voucher: action.payload.Voucher[1].transaction,
          },
          {
            name: 'Mar',
            value: action.payload.Paymee[2].transaction,
            color: 'gray',
            Topnet: action.payload.Topnet[2].transaction,
            Paymee: action.payload.Paymee[2].transaction,
            Voucher: action.payload.Voucher[2].transaction,
          },
          {
            name: 'Avr',
            value: action.payload.Paymee[3].transaction,
            color: 'gray',
            Topnet: action.payload.Topnet[3].transaction,
            Paymee: action.payload.Paymee[3].transaction,
            Voucher: action.payload.Voucher[3].transaction,
          },
          {
            name: 'Mai',
            value: action.payload.Paymee[4].transaction,
            color: 'gray',
            Topnet: action.payload.Topnet[4].transaction,
            Paymee: action.payload.Paymee[4].transaction,
            Voucher: action.payload.Voucher[4].transaction,
          },
          {
            name: 'Jun',
            value: action.payload.Paymee[5].transaction,
            color: 'gray',
            Topnet: action.payload.Topnet[5].transaction,
            Paymee: action.payload.Paymee[5].transaction,
            Voucher: action.payload.Voucher[5].transaction,
          },
          {
            name: 'Jul',
            value: action.payload.Voucher[6].transaction,
            color: 'blue',
            Topnet: action.payload.Topnet[6].transaction,
            Paymee: action.payload.Paymee[6].transaction,
            Voucher: action.payload.Voucher[6].transaction,
          },
          {
            name: 'Aoû',
            value: action.payload.Paymee[7].transaction,
            color: 'gray',
            Topnet: action.payload.Topnet[7].transaction,
            Paymee: action.payload.Paymee[7].transaction,
            Voucher: action.payload.Voucher[7].transaction,
          },
          {
            name: 'Sep',
            value: action.payload.Paymee[8].transaction,
            color: 'gray',
            Topnet: action.payload.Topnet[8].transaction,
            Paymee: action.payload.Paymee[8].transaction,
            Voucher: action.payload.Voucher[8].transaction,
          },
          {
            name: 'Oct',
            value: action.payload.Paymee[9].transaction,
            color: 'red',
            Topnet: action.payload.Topnet[9].transaction,
            Paymee: action.payload.Paymee[9].transaction,
            Voucher: action.payload.Voucher[9].transaction,
          },
          {
            name: 'Nov',
            value: action.payload.Paymee[10].transaction,
            color: 'gray',
            Topnet: action.payload.Topnet[10].transaction,
            Paymee: action.payload.Paymee[10].transaction,
            Voucher: action.payload.Voucher[10].transaction,
          },
          {
            name: 'Déc',
            value: action.payload.Topnet[11].transaction,
            color: 'orange',
            Topnet: action.payload.Topnet[11].transaction,
            Paymee: action.payload.Paymee[11].transaction,
            Voucher: action.payload.Voucher[11].transaction,
          },
        ],
      };
    }
    case GET_USER_STATS: {
      return {
        ...state,
        stats: [
          ...state.stats,

          {
            name: 'Topup',
            amount: action.payload.topup.amount.All,
            img: '/assets/images/icons/recharge.png',
            color: ProductColors.LIGHT_PINK,
          },
          {
            name: 'Voucher',
            amount: action.payload.voucher.amount.All,
            img: '/assets/images/icons/voucher.png',
            color: ProductColors.PARROT,
          },
          {
            name: 'Paiement facture',
            amount: action.payload.poste_recharge.amount.All,
            img: '/assets/images/icons/invoice.png',
            color: ProductColors.GREY,
          },

          {
            name: 'Paymee',
            amount: action.payload.poste_payement.amount.All,
            img: '/assets/images/icons/money.png',
            color: ProductColors.BLUE,
          },
          {
            name: 'Recharge carte',
            amount: action.payload.poste_recharge.amount.All,
            img: '/assets/images/icons/cards.png',
            color: ProductColors.GREY,
          },
          {
            name: 'Topnet',
            amount: action.payload.topnet.amount.All,
            img: '/assets/images/icons/money.png',
            color: ProductColors.PINK,
          },
        ],
        statsTr: [
          ...state.stats,

          {
            name: 'Topup',
            amount: action.payload.topup.amount.All,
            img: '/assets/images/icons/image1.png',
            color: ProductColors.LIGHT_PINK,
          },
          {
            name: 'Voucher',
            amount: action.payload.voucher.amount.All,
            img: '/assets/images/icons/image3.png',
            color: ProductColors.PARROT,
          },
          {
            name: 'Paiement facture',
            amount: action.payload.poste_recharge.amount.All,
            img: '/assets/images/icons/image1.png',
            color: ProductColors.GREY,
          },
          {
            name: 'Recharge carte',
            amount: action.payload.poste_recharge.amount.All,
            img: '/assets/images/icons/image1.png',
            color: ProductColors.GREY,
          },
          {
            name: 'Paymee',
            amount: action.payload.poste_payement.amount.All,
            img: '/assets/images/icons/image4.png',
            color: ProductColors.BLUE,
          },
          {
            name: 'Topnet',
            amount: action.payload.topnet.amount.All,
            img: '/assets/images/icons/image6.png',
            color: ProductColors.PINK,
          },
        ],
      };
    }
    case GET_ENABLED_SERVICES: {
      //here we are changing the returned object structure to get directely serviceName
      return {
        ...state,
        topupServices: action.payload
          .find((serv) => serv.codeService === 'TU')
          ?.services.reduce(
            (acc, cur) => ({...acc, [cur.code]: cur.status}),
            {},
          ),
        voucherServices: action.payload
          .find((serv) => serv.codeService === 'Voucher')
          ?.services.reduce(
            (acc, cur) => ({...acc, [cur.code]: cur.status}),
            {},
          ),
        invoiceServices: action.payload
          .find((serv) => serv.codeService === 'PF')
          ?.services.reduce(
            (acc, cur) => ({...acc, [cur.code]: cur.status}),
            {},
          ),
        bookingServices: action.payload
          .find((serv) => serv.codeService === 'BOOKING')
          ?.services.reduce(
            (acc, cur) => ({...acc, [cur.code]: cur.status}),
            {},
          ),
        gamingServices: action.payload
          .find((serv) => serv.codeService === 'GAMING')
          ?.services.reduce(
            (acc, cur) => ({...acc, [cur.code]: cur.status}),
            {},
          ),
        internetSabbaServices: action.payload
          .find((serv) => serv.codeService === 'INTERNET_SABBA')
          ?.services.reduce(
            (acc, cur) => ({...acc, [cur.code]: cur.status}),
            {},
          ),
      };
    }
    default:
      return state;
  }
};
export default authReducer;
