import dataAxios from '@crema/services/data/axiosData';
import {appIntl} from '@crema/utility/Utils';
import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  SET_MINTROUTE_AMOUNT,
  SET_MINTROUTE_VOUCHER_PRINT,
  SET_PRODUCT_VIEW_TYPE,
  GET_TYPE_PRODUCT,
} from 'shared/constants/ActionTypes';
import {fetchError} from './Common';

export const onGetMintroute = (endpoint, actionType, params) => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .get('/mintroute' + endpoint, {params: params})
      .then((res) => {
        if (res.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          if (res.data.data) {
            dispatch({type: actionType, payload: res.data.data});
          } else {
            dispatch({type: actionType, payload: {data: [], totalPages: 0}});
          }
        } else {
          dispatch(fetchError(messages['message.somethingWentWrong']));
        }
      })
      .catch((error) => {
        dispatch({
          type: FETCH_ERROR,
        });
      });
  };
};
export const setViewType = (viewType) => {
  return (dispatch) => {
    dispatch({type: SET_PRODUCT_VIEW_TYPE, payload: viewType});
  };
};

export const onConsulOfferMintroute = (
  endpoint,
  body,
  setActivateDialogOpen,
  setSelected,
) => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .post('/mintroute' + endpoint, body)
      .then((res) => {
        if (res.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          if (res.data.code === '1050') {
            dispatch({
              type: FETCH_ERROR,
              payload:
                'Ce service est non disponble pour le moment! Veuillez réessayer plus tard',
            });
          } else {
            if (endpoint === '/voucher') {
              //console.log('hjhjkkjlkjl',res.data.data.code)
              if (res.data.data.code === 2000) {
                dispatch(fetchError('Votre solde est insuffisant'));
                setActivateDialogOpen(false);
              } else if (res.data.data.code === 2202) {
                setActivateDialogOpen(true);
                dispatch({
                  type: SET_MINTROUTE_VOUCHER_PRINT,
                  payload: res.data.data.voucher,
                });
              }
            } else if (res.data?.data?.prix) {
              setActivateDialogOpen(true);
              dispatch({
                type: SET_MINTROUTE_AMOUNT,
                payload: res.data.data.prix,
              });
            }
          }
        } else {
          dispatch(fetchError(messages['message.somethingWentWrong']));
        }
      })
      .catch((error) => {
        dispatch({
          type: FETCH_ERROR,
          payload:
            'Ce service est non disponble pour le moment! Veuillez réessayer plus tard',
        });
      });
  };
};

export const getAllTypes = () => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .get('/mintroute/type')
      .then((res) => {
        if (res.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          if (res.data.status === 'success') {
            dispatch({
              type: GET_TYPE_PRODUCT,
              payload: res.data.data,
            });
          } else {
            dispatch({
              type: GET_TYPE_PRODUCT,
              payload: [],
            });
          }
        } else {
          dispatch(fetchError(messages['message.somethingWentWrong']));
        }
      })
      .catch((error) => {
        dispatch(fetchError());
      });
  };
};
