import {
  GET_ALL_BLOGS_LIST,
  GET_ALL_BLOGS_DELETED,
  SET_NEW_BLOG,
  UPDATE_BLOG,
  ACTIVATE_BLOG,
} from '../../shared/constants/ActionTypes';

const initialSettings = {
  posts: [],
  deletedList: [],
  extraData: {},
  totalPages: 0,
  oneblog: {},
  updatingBlog: {},
  activateBlog: {},
};

const BlogReducer = (state = initialSettings, action) => {
  switch (action.type) {
    //   case GET_ALL_BLOGS_LIST:
    //     return {
    //       ...state,
    //       blogs: action.payload.data?.data,
    //       totalPages: action.payload.data?.totalPages,
    //     };
    case GET_ALL_BLOGS_LIST:
      return {
        ...state,
        posts: action.payload.data.data, //error
        totalPages: action.payload.data?.totalPages,
      };

    case GET_ALL_BLOGS_DELETED:
      return {
        ...state,
        deletedList: action.payload?.data?.data,
        totalPages: action.payload?.data?.totalPages,
        extraData: action.payload?.extraData,
      };

    case UPDATE_BLOG:
      return {
        ...state,
        updatingBlog: action.payload,
      };
    case ACTIVATE_BLOG:
      return {
        ...state,
        activateBlog: action.payload,
      };
    case SET_NEW_BLOG:
      return {...state, oneblog: action.payload};
    default:
      return state;
  }
};

export default BlogReducer;
