import dataAxios from '@crema/services/data/axiosData';
import {appIntl} from '@crema/utility/Utils';
import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  GET_ALL_CATEGORIES,
  GET_ALL_CITIES,
  GET_ALL_RESERVATIONS,
  GET_QUOTE_DATA_FROM_API,
  GET_VOUCHER_STATUS,
  SET_HOTELS_FILTERS_PARAMS,
  SET_ID_CITY,
  SET_PRODUCT_VIEW_TYPE,
  SET_QUOTE_DATA,
  SET_SINGLE_HOTEL_DATA,
  SHOW_MESSAGE,
} from 'shared/constants/ActionTypes';
import {fetchError} from './Common';

export const onGetAllCities = (params) => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .get('/hotel/cities', {params: params})
      .then((res) => {
        if (res.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          if (res.data.data) {
            dispatch({
              type: GET_ALL_CITIES,
              payload: res.data.data,
            });
          }
        } else {
          dispatch(fetchError(messages['message.somethingWentWrong']));
        }
      })
      .catch((error) => {
        dispatch({
          type: FETCH_ERROR,
        });
      });
  };
};
export const onGetAllCategories = (params) => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .get('/hotel/categories', {params: params})
      .then((res) => {
        if (res.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          if (res.data.data) {
            dispatch({
              type: GET_ALL_CATEGORIES,
              payload: res.data.data,
            });
          }
        } else {
          dispatch(fetchError(messages['message.somethingWentWrong']));
        }
      })
      .catch((error) => {
        dispatch({
          type: FETCH_ERROR,
        });
      });
  };
};
export const getQuote = (params) => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .get('/hotel/hotelQote', {params: params})
      .then((res) => {
        if (res.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          if (res.data.data.Hotel) {
            dispatch({
              type: GET_QUOTE_DATA_FROM_API,
              payload: res.data.data.Hotel,
            });
          } else {
            dispatch({
              type: GET_QUOTE_DATA_FROM_API,
              payload: undefined,
            });
          }
        } else {
          dispatch(fetchError(messages['message.somethingWentWrong']));
        }
      })
      .catch((error) => {
        dispatch({
          type: FETCH_ERROR,
        });
      });
  };
};
export const onGetAllHotels = (params, actionType) => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .get('/hotel', {params: params})
      .then((res) => {
        if (res.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          if (res.data.data.Hotels.length > 0) {
            dispatch({
              type: actionType,
              payload: res.data.data,
            });
          } else {
            dispatch({
              type: actionType,
              payload: [],
            });
          }
        } else {
          dispatch(fetchError(messages['message.somethingWentWrong']));
        }
      })
      .catch((error) => {
        dispatch({
          type: FETCH_ERROR,
        });
      });
  };
};
export const onGetAllReservations = (params) => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .get('/hotel/bookingHotel/user', {params: params})
      .then((res) => {
        if (res.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          if (res.data.code === 2202) {
            dispatch({
              type: GET_ALL_RESERVATIONS,
              payload: res.data.data,
            });
          } else {
            dispatch({
              type: GET_ALL_RESERVATIONS,
              payload: [],
            });
          }
        } else {
          dispatch(fetchError(messages['message.somethingWentWrong']));
        }
      })
      .catch((error) => {
        dispatch({
          type: FETCH_ERROR,
        });
      });
  };
};
export const setViewType = (viewType) => {
  return (dispatch) => {
    dispatch({type: SET_PRODUCT_VIEW_TYPE, payload: viewType});
  };
};

export const setFiltersParam = (
  adult,
  child,
  rooms,
  startDate,
  endDate,
  pax,
  cityId,
) => {
  return (dispatch) => {
    dispatch({
      type: SET_HOTELS_FILTERS_PARAMS,
      payload: {
        rooms: rooms,
        adult: adult,
        child: child,
        startDate: startDate,
        endDate: endDate,
        pax: pax,
        cityId: cityId,
      },
    });
  };
};
export const setQuoteData = (data) => {
  return (dispatch) => {
    dispatch({
      type: SET_QUOTE_DATA,
      payload: data,
    });
  };
};
export const setSingleHotelData = (data) => {
  return (dispatch) => {
    dispatch({
      type: SET_SINGLE_HOTEL_DATA,
      payload: data,
    });
  };
};
export const setIdCity = (data) => {
  return (dispatch) => {
    dispatch({
      type: SET_ID_CITY,
      payload: data,
    });
  };
};
export const postReservation = (service, setData) => {
  const {messages} = appIntl();
  return (dispatch) => {
    const body = service;
    dispatch({type: FETCH_START});
    dataAxios
      .post('/hotel/bookingHotel', body)
      .then((data) => {
        if (data.status === 200) {
          if (data.data.code === 2202) {
            dispatch({
              type: FETCH_SUCCESS,
              //payload: 'Votre alimentation a été éffectuée avec success',
            });
            dispatch({
              type: SHOW_MESSAGE,
              payload: 'Votre Reservaion été effecutée avec succès',
            });

            setData(data.data.data.hotel);

            dispatch({
              type: GET_VOUCHER_STATUS,
              payload: true,
            });
          } else if (data.data.data.code === 2000) {
            dispatch(fetchError('Votre solde est insuffisant'));
          } else {
            dispatch(fetchError(messages['message.somethingWentWrong']));
          }
        } else {
          dispatch(fetchError(messages['message.somethingWentWrong']));
        }
      })
      .catch((error) => {
        dispatch(fetchError(messages['message.somethingWentWrong']));
      });
  };
};
